import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MathJaxContext } from 'better-react-mathjax';

import {
  UserProvider,
  BarsProvider,
  NavProvider,
  ToastProvider,
  NotificationProvider,
} from 'contexts';
import { AppBar, SideBar } from 'components/bar';
import { BasicRoutes } from './routes';
import GlobaOps from './globalops';

import './internationalization';
import './App.scss';

const config = {
  tex: {
    inlineMath: [['$', '$']],
  },
  loader: {
    load: ['[tex]/boldsymbol'],
  },
};

const App = () => (
  <ToastProvider>
    <UserProvider>
      <NotificationProvider>
        <BrowserRouter>
          <NavProvider>
            <BarsProvider>
              <MathJaxContext config={config}>
                <div className="app-canvas">
                  <AppBar />

                  <div className="body-container">
                    <SideBar />
                    <div className="page-container">
                      <BasicRoutes />
                    </div>
                  </div>
                </div>
              </MathJaxContext>
            </BarsProvider>
            <GlobaOps />
          </NavProvider>
        </BrowserRouter>
      </NotificationProvider>
    </UserProvider>
  </ToastProvider>
);

export default App;
