import React, { lazy, Suspense, useContext, useMemo } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import queryString from 'query-string';
import { UserContext } from 'contexts';
import { APP_CONSTANTS } from 'common/strings';

const LoginPage = lazy(() => import('pages/login/login'));
const ForgotPasswordPage = lazy(() => import('pages/forgot-password/forgot-password'));
const ResetPasswordPage = lazy(() => import('pages/forgot-password/reset-password'));
const VerifyEmailPage = lazy(()=> import('pages/email-confirmation/verify-email'))

const DashboardPage = lazy(() => import('pages/home/dashboard'));
const classPage = lazy(() => import('pages/classes/classes'));
const InstructorSignupPage = lazy(() => import('pages/signup/instructor/inst-signup'));
const StudentSignupPage = lazy(() => import('pages/signup/indep-account/signup-form'));
const JoinClassPage = lazy(() => import('pages/signup/student/join-a-class'));

const AssignmentsPage = lazy(() => import('pages/assignments/assignments'));
const CalendarPage = lazy(() => import('pages/calendar/calendar'));
const ContentsPage = lazy(() => import('pages/contents/contents'));
const ContactUsPage = lazy(() => import('pages/contact-us/contact-us'));
const SearchResultPage = lazy(() => import('pages/search-result/search-result'));
const SubjectDetailPage = lazy(() => import('pages/contents/subject-detail'));
const InstitutionList = lazy(() => import('pages/admin/institutions/institution-list'));
const IndepAccountList = lazy(() =>
  import('pages/admin/indep-accounts/indep-account-list'),
);
const InstructorList = lazy(() =>
  import('./pages/admin/instructor-accounts/instructor-list'),
);
const AdminList = lazy(() => import('pages/admin/admin-accounts/admin-list'));
const AccountPage = lazy(() => import('pages/account/account'));
const PreferencePage = lazy(() => import('pages/preference/preference'));
const AnnouncementPage = lazy(() => import('pages/announcement/announcement'));
const HelpPage = lazy(() => import('pages/help/help'));
const AboutUsPage = lazy(() => import('pages/about-us/about-us'));
const CreateAnnouncementPage = lazy(() =>
  import('pages/announcement/create-announcement'),
);

const AccountRenewal = lazy(() => import('pages/my-purchases/account-renewal'));
const PurchaseHistory = lazy(() => import('pages/my-purchases/my-purchases'));

const AttributionsPage = lazy(() => import('pages/contents/attributions'));

const CourseLinkingPage = lazy(() => import('pages/lti/course-linking')); 
const AssignmentLinkingPage = lazy(() => import('pages/lti/assignment-linking')); 
const EnrollStudent = lazy(()=> import('pages/classes/enroll'));

const LtiErrorPage = lazy(() => import('pages/lti/lti-error'));

function MainRouter({ noAuthPages, authRequiredPages }) {
  const { user, isAuthenticated, loading, isAnatomageAdmin, isInstructor, isIndepAccount, signOut } =
    useContext(UserContext);
  const location = useLocation();
  const queries = queryString.parse(location.search);
  // const { t } = useTranslation();

  const authenticated = useMemo(
    () =>
      user ||
      isAuthenticated() ||
      (queries.access_token && queries.expires_in, queries.refresh_token),
    [user],
  );

  if (window.location.pathname === '/signout') {
    signOut(queries.clear_session !== 'false'); // signing out from 3DonCloud should not clear the other sessions
  }

  if (loading) return null;

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route
          path="/"
          element={
            authenticated ? <Navigate to={APP_CONSTANTS.ROUTES.HOME} /> : <Navigate to={`${APP_CONSTANTS.ROUTES.SIGNIN}${window.location.search}`} /> 
          }
        />
        {noAuthPages.map((item) => (
          <Route
            key={item.path}
            path={item.path}
            element={
              (item.path === APP_CONSTANTS.ROUTES.SIGNIN ||
                item.path === APP_CONSTANTS.ROUTES.SIGNUP ||
                item.path === APP_CONSTANTS.ROUTES.RESETPASSWORD ||
                item.path === APP_CONSTANTS.ROUTES.FORGOTPASSWORD ||
                item.path === APP_CONSTANTS.ROUTES.LTI.ERROR
                // || item.path === APP_CONSTANTS.ROUTES.VERIFYEMAIL
              ) &&
              authenticated ? (
                <Navigate to={APP_CONSTANTS.ROUTES.HOME} />
              ) : (
                <item.component />
              )
            }
          />
        ))}

        {authRequiredPages.map((item) => {
          if (!isInstructor() && CU_ROUTES.includes(item.path)) {
            return (
              <Route
                key={item.path}
                path={item.path}
                element={<Navigate to={APP_CONSTANTS.ROUTES.HOME} />}
              />
            );
          }

          if (!isIndepAccount() && INDEP_ROUTES.includes(item.path)) {
            return (
              <Route
                key={item.path}
                path={item.path}
                element={<Navigate to={APP_CONSTANTS.ROUTES.HOME} />}
              />
            );
          }

          return (
            <Route
              key={item.path}
              path={item.path}
              element={
                user || isAuthenticated() ? (
                  <item.component />
                ) : (
                  <Navigate
                    to={APP_CONSTANTS.ROUTES.SIGNIN}
                    state={{ from: location.pathname }}
                  />
                )
              }
            />
          );
        })}

        {adminPages.map((item) => (
          <Route
            key={item.path}
            path={item.path}
            element={
              isAnatomageAdmin() && isAuthenticated() ? (
                <item.component />
              ) : (
                <Navigate to={APP_CONSTANTS.ROUTES.HOME} />
              )
            }
          />
        ))}

        <Route path="*" element={<Navigate to={APP_CONSTANTS.ROUTES.SIGNIN} />} />
      </Routes>
    </Suspense>
  );
}

const CU_ROUTES = [
  APP_CONSTANTS.ROUTES.ANNOUNCEMENT.CREATE,
  APP_CONSTANTS.ROUTES.ASSIGNMENT.CREATE,
  APP_CONSTANTS.ROUTES.CLASSES.CREATE,
  APP_CONSTANTS.ROUTES.ANNOUNCEMENT.EDIT,
  APP_CONSTANTS.ROUTES.ASSIGNMENT.EDIT,
  APP_CONSTANTS.ROUTES.CLASSES.EDIT,
];

const INDEP_ROUTES = [
  APP_CONSTANTS.ROUTES.RENEWAL,
  APP_CONSTANTS.ROUTES.MYPURCHASES
]

const adminPages = [
  { path: APP_CONSTANTS.ROUTES.ADMIN.ADMIN_LIST.BASE, component: AdminList },
  { path: APP_CONSTANTS.ROUTES.ADMIN.INSTITUTION_LIST.BASE, component: InstitutionList },
  {
    path: APP_CONSTANTS.ROUTES.ADMIN.INSTITUTION_LIST.CREATE,
    component: InstitutionList,
  },

  { path: APP_CONSTANTS.ROUTES.ADMIN.INSTR_LIST.BASE, component: InstructorList },
  {
    path: APP_CONSTANTS.ROUTES.ADMIN.INDEP_ACCOUNT_LIST.BASE,
    component: IndepAccountList,
  },
  // { path: APP_CONSTANTS.ROUTES.ADMIN.RATINGS, component: <div /> },
];

const noAuthPages = [
  { path: APP_CONSTANTS.ROUTES.SIGNIN, component: LoginPage },
  { path: APP_CONSTANTS.ROUTES.INST_SIGNUP, component: InstructorSignupPage },
  { path: APP_CONSTANTS.ROUTES.STUD_SIGNUP, component: StudentSignupPage },
  { path: APP_CONSTANTS.ROUTES.JOIN_CLASS, component: JoinClassPage },
  { path: APP_CONSTANTS.ROUTES.FORGOTPASSWORD, component: ForgotPasswordPage },
  { path: APP_CONSTANTS.ROUTES.RESETPASSWORD, component: ResetPasswordPage },
  { path: APP_CONSTANTS.ROUTES.ABOUTUS, component: AboutUsPage },
  { path: APP_CONSTANTS.ROUTES.FEEDBACK, component: DashboardPage },
  { path: APP_CONSTANTS.ROUTES.HELP, component: HelpPage },
  { path: APP_CONSTANTS.ROUTES.VERIFYEMAIL, component: VerifyEmailPage },
  { path: APP_CONSTANTS.ROUTES.LTI.ERROR, component: LtiErrorPage}

];

const authRequiredPages = [
  { path: APP_CONSTANTS.ROUTES.ACCOUNT, component: AccountPage },
  { path: APP_CONSTANTS.ROUTES.HOME, component: DashboardPage },
  { path: APP_CONSTANTS.ROUTES.ANNOUNCEMENT.BASE, component: AnnouncementPage },
  { path: APP_CONSTANTS.ROUTES.ANNOUNCEMENT.CREATE, component: CreateAnnouncementPage },
  { path: APP_CONSTANTS.ROUTES.ANNOUNCEMENT.EDIT, component: CreateAnnouncementPage },
  { path: APP_CONSTANTS.ROUTES.CALENDAR, component: CalendarPage },
  { path: APP_CONSTANTS.ROUTES.ASSIGNMENT.BASE, component: AssignmentsPage },
  { path: APP_CONSTANTS.ROUTES.ASSIGNMENT.CREATE, component: AssignmentsPage },
  { path: APP_CONSTANTS.ROUTES.ASSIGNMENT.EDIT, component: AssignmentsPage },
  { path: APP_CONSTANTS.ROUTES.ASSIGNMENT.DETAIL, component: AssignmentsPage },
  { path: APP_CONSTANTS.ROUTES.CONTENTS.BASE, component: ContentsPage },
  { path: APP_CONSTANTS.ROUTES.CONTENTS.DETAIL, component: SubjectDetailPage },
  { path: APP_CONSTANTS.ROUTES.CONTENTS.SEARCH, component: SearchResultPage },
  { path: APP_CONSTANTS.ROUTES.CONTENTS.ATTRIBUTIONS, component: AttributionsPage },
  { path: APP_CONSTANTS.ROUTES.CLASSES.BASE, component: classPage },
  { path: APP_CONSTANTS.ROUTES.CLASSES.EDIT, component: classPage },
  { path: APP_CONSTANTS.ROUTES.CLASSES.DETAIL, component: classPage },
  { path: APP_CONSTANTS.ROUTES.PREFERENCE, component: PreferencePage },
  { path: APP_CONSTANTS.ROUTES.CONTACTUS, component: ContactUsPage },
  { path: APP_CONSTANTS.ROUTES.RENEWAL, component: AccountRenewal },
  { path: APP_CONSTANTS.ROUTES.MYPURCHASES, component: PurchaseHistory },
  { path: APP_CONSTANTS.ROUTES.COURSELINKING, component: CourseLinkingPage },
  { path: APP_CONSTANTS.ROUTES.ASSIGNMENTLINKING, component: AssignmentLinkingPage },
  { path: APP_CONSTANTS.ROUTES.CLASSES.ENROLL, component: EnrollStudent}

];

const BasicRoutes = () => (
  <MainRouter
    noAuthPages={noAuthPages}
    authRequiredPages={authRequiredPages}
    adminPages={adminPages}
  />
);

export { BasicRoutes };
