import React from 'react';

import './button-switch.style.scss';

export default function ButtonSwitch({ value, disabled, options, onChanged }) {
  return (
    <div className="bsw-container">
      {options.map((op, index) => (
        <SwitchItem
          key={`button-switch-${index}`}
          selected={value.id === op.id}
          text={op.text}
          onSelected={() => {
            if (!disabled) {
              onChanged(op);
            }
          }}
        />
      ))}
    </div>
  );
}

const SwitchItem = ({ selected, text, onSelected }) => (
  <div
    className={`${selected ? 'switch-item-selected' : 'switch-item-default'}`}
    role="none"
    onClick={(e) => {
      e.stopPropagation();
      onSelected();
    }}
  >
    {text}
  </div>
);
