import { useState, useMemo, useEffect, useCallback, useRef, useContext } from 'react';
import queryString from 'query-string';
import { v1 } from 'common/apis';
import { apiClient, setupClient } from 'common/api-client';
import { UserContext, ToastContext } from 'contexts';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import {
  setAccessToken,
  accessToken,
  removeAccessToken,
  setRefreshToken,
  removeRefreshToken,
} from 'common/storage';
import { APP_CONSTANTS } from 'common/strings';

export default function UserProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [preferences, setPreferences] = useState({ timeoutperiod: 20 });
  const [licenses, setLicenses] = useState([]);
  const [classLinked, setClassLinked] = useState(false);
  const { showTimeout, setShowTimeout } = useContext(ToastContext);
  const checkIntervalRef = useRef();
  const activeRef = useRef(false);
  const activeTimeRef = useRef();
  const query = queryString.parse(window.location.search);

  useEffect(() => {
    setLoading(true);

    // LTI Error Page -- reset credentials
    if (query && query.lti_err) {
      removeAccessToken();
      removeRefreshToken();
    }

    // LTI launch
    if (query && query.user_id && query.reg_id && query.state && query.dep_id && query.context_id) {
      validLtiLaunch();
    } 

    // Google Classroom launch
    else if (query && (query.addOnToken || query.attachmentId || query.login_hint)) 
    {
      googleClassroomLaunch();
    } 
    
    else
    {
      initAccount();
    }
  }, []);

  const validLtiLaunch = useCallback(async () => {
    setLoading(true);
    try {
      // check if session was initiated from lms link
      const res = await apiClient.get(v1.account.lti, {
        withCredentials: true,
        params: {
          user_id: query.user_id,
          reg_id: parseInt(query.reg_id, 10),
          state: query.state,
          deployment_id: query.dep_id,
          deep: query.assignment || query.class_id ? 'true' : '',
          context_id: query.context_id
        },
      });

      if (res.data === 'Session expired or does not exist.'){
        signOut(true);
      }

      if (res.data) {
        const { data } = res;
        if (data.access_token && data.access_token.length > 0) {
          initAccount(data, true, false);
        }
      }
    } catch (err) {
      // 
    }
    setLoading(false);
  }, [query.user_id, query.reg_id, query.state]);

  const googleClassroomLaunch = useCallback(async () => {
    setLoading(true);

    try {
      // check if user exists
      const res = await apiClient.get(v1.account.google, {
        withCredentials: true,
        params: {
          login_hint: query.login_hint,
          course_id: query.courseId || query.course_id,
          deep: query.itemType === 'courseWork' ? 'true' : '',
          addOn: query.addOnToken || addOnToken(),
          itemId: query.itemId,
          submissionId: query.submissionId
        },
      });

      if (res.data === 'Session expired or does not exist.'){
        removeAccessToken();
        removeRefreshToken();
        setLoading(false);
        return;
      }

      if (res.data) {
        const { data } = res;
        if (data.access_token && data.access_token.length > 0) {
          initAccount(data, true, false);
        }
      }
    } catch (err) {
      // 
    }

  }, [query]);


  useEffect(() => {
    let cancel;
    const handleTabfocus = () => {
      if (document.visibilityState === 'hidden') {
        clearCheckActivity();
      }
    };
    const handleFocus = async (e) => {
      if (document.visibilityState === 'visible') {
        if (!isAuthenticated()) activeRef.current = false;
        const dialog = document.querySelector('.timeout-dialog');

        if (!dialog && user) {
          const res = await checkLastActivity();

          if (res) {
            initCheckActivity();
          } else {
            window.location.href = APP_CONSTANTS.ROUTES.SIGNIN;
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleTabfocus);
    window.addEventListener('focus', handleFocus);
    return () => {
      if (cancel) {
        cancel();
      }
      clearCheckActivity();
      document.removeEventListener('visibilitychange', handleTabfocus);
      window.removeEventListener('focus', handleFocus);
    };
  }, [user]);

  const safeJwtDecode = (token) => {
    try {
      return jwt_decode(token);  // Attempt to decode the token
    } catch (error) {
      return null;
    }
  };

  const initAccount = async (token, store = true, lax = true) => {
    setLoading(true);
    let tkn;
    if (!token) {
      tkn = accessToken();
    } else {
      tkn = token.access_token;
    }

    if (tkn) {
      let decoded;
      try {
        decoded = safeJwtDecode(tkn);
      } catch (e) {
        // 
      }

      if (decoded) {
        setupClient(tkn, decoded.AccountId);
      }

      // Google Classroom: course linking flow
      if (query && query.itemType && (query.itemType === '1' || query.itemType === '2')) {
        await fetchUser();
        setLoading(false);
        return decoded;
      }

      // Google Classroom: assignment linking flow
      if (query && query.class_id && query.item_id && !query.create_deep) {
        await fetchUser();
        setLoading(false);
        return decoded;
      }

      // Launch to specific assignment
      if (query && query.id && query.class_id) {
        await fetchUser();

        setLoading(false);
        return decoded;
      }

      if (store) {
        if (token) {
          setRefreshToken(token.refresh_token, token.refresh_expires, lax);
          setAccessToken(token.access_token, '', lax);
        }

        if (decoded && decoded.AccountTypeId != null && parseInt(decoded.AccountTypeId, 10) === 1) {
          window.open(v1.admin.adminview(), '_self');
        } else {
          await fetchUser();
          await fetchPreference();
          await fetchLicenses();
          
          // Google Classroom: check if class is linked
          let linked = -1;
          try {
            if (isAddOn() && !query.addOnToken){
              const res = await apiClient.get(v1.lti.get_google_linked_class, {
                params: {
                  course_id: GoogleCourseId()
                },
              });
              const {data}= res;
              if (data && data[0].class_id != null) linked = data[0].class_id;
            }
          } catch (e) {
            if (e.response && e.response.status === 404 && isDeepLaunch()) {
                  // Class Not Linked Error
                  setLoading(false);
                  const navigation_url = 'lti-error?lti_err=1';
                  window.history.replaceState(null, '', navigation_url);
                  window.location.href = navigation_url;
                  return decoded;
            }
           
            if (isDeepLaunch()){
                  // Wrong Course Error
                  setLoading(false);
                  const navigation_url = 'lti-error?lti_err=4';
                  window.history.replaceState(null, '', navigation_url);
                  window.location.href = navigation_url;
                  return decoded;
            }
          }

            if (decoded && decoded.lti_linked_class && decoded.lti_linked_class === '-1') {
            setClassLinked(false);
          }
          else {
            setClassLinked(true);
          }

          // Google Classroom Redirects
            if (query && isAddOn()) {

            // Check if class needs to be linked
            if (
              decoded && 
              decoded.AccountTypeId != null &&
              parseInt(decoded.AccountTypeId, 10) === 3 &&
              parseInt(decoded.lti_context_id, 10) === -1 &&
              decoded.add_on != null &&
              linked === -1
            ) {
              setLoading(false);
              const navigation_url = `/courselinking?google_courselink=true&itemType=${query && query.itemType === 'courseWork' ? 1 : 2}`;
              window.history.replaceState(null, '', navigation_url);
              window.location.href=navigation_url;
              return decoded;
            }
            
            // Creating an assignment
            if (decoded && 
              decoded.AccountTypeId != null &&
              parseInt(decoded.AccountTypeId, 10) === 3 &&
              query && !query.assignment && query.login_hint && query.itemType === 'courseWork' && decoded.add_on != null && !lax) {
                setLoading(false);
                const navigation_url = `/assignmentlinking?login_hint=${query.login_hint}&class_id=${parseInt(decoded.lti_context_id, 10)}&course_id=${parseInt(decoded.lti_course_id, 10)}&item_id=${parseInt(decoded.item_id, 10)}`;
                window.history.replaceState(null, '', navigation_url);
                window.location.href= navigation_url;
                return decoded;
            }

            // Creating a general link
            if (decoded && 
              decoded.AccountTypeId != null &&
              parseInt(decoded.AccountTypeId, 10) === 3 &&
              query && query.login_hint && query.itemType === 'courseWorkMaterials' && query.addOnToken && !lax) {
              const response = await apiClient.post(v1.lti.google_response, {
                login_hint: query.login_hint,
                course_id: query.courseId,
                item_id: query.itemId,
                add_on_token: query.addOnToken
              });
    
              window.parent.postMessage({
                type: 'Classroom',
                action: 'closeIframe',
              }, '*');

              return decoded;
            }

            // Launch to a specific assignment
            if (query && query.assignment && query.assignment !== '') {

              // Assignment Link Error
              if (linked === -1 || linked !== parseInt(query.class_id, 10)) {
                setLoading(false);
                const navigation_url = 'lti-error?lti_err=3';
                window.history.replaceState(null, '', navigation_url);
                window.location.href = navigation_url;
                return decoded;
              }
              
              try {
                await apiClient.get(v1.assignments.assignment(query.assignment), {
                  params: {
                    include_user_metric: false,
                  },
                });
              }
              catch(e) {
                // Assignment Link Error
                setLoading(false);
                const navigation_url = 'lti-error?lti_err=3';
                window.history.replaceState(null, '', navigation_url);
                window.location.href = navigation_url;
                return decoded;
              }

              setLoading(false);
              const navigation_url = `/assignments/details?id=${query.assignment}&class_id=${query.class_id}&tab=${query.tab}`;
              window.history.replaceState(null, '', navigation_url);
              window.location.href= navigation_url;
              return decoded;
            }

          }
          // LTI Redirects
          else {
            // Creating an assignment
            if (query && query.class_id && !lax) {
              setLoading(false);
              const navigation_url = `/assignmentlinking?user_id=${query.user_id}&reg_id=${query.reg_id}&class_id=${query.class_id}`;
              window.history.replaceState(null, '', navigation_url);
              window.location.href = navigation_url;
            }

            // Launch to specific assignment
            if (query && query.assignment && query.assignment !== '' && !lax) {
              setLoading(false);
              const navigation_url = `/assignments/details?id=${query.assignment}&class_id=${query.class_id}&tab=${query.tab}`;
              window.history.replaceState(null, '', navigation_url);
              window.location.href = navigation_url;
            }

            // Check if class needs to be linked
            if (
              decoded && 
              decoded.AccountTypeId != null &&
              parseInt(decoded.AccountTypeId, 10) === 3 &&
              query &&
              query.user_id &&
              query.reg_id &&
              query.state &&
              query.dep_id &&
              !query.class_id &&
              !query.id
            ) {
              try {
                const check = await apiClient.get(v1.lti.check_class_linkage, {
                  params: {
                    user_id: query.user_id  ? query.user_id  : decoded.lti_user_id,
                    deployment_id: query.dep_id ? query.dep_id : decoded.lti_deployment_id,
                    reg_id: parseInt(query.reg_id ? query.reg_id : decoded.lti_registration_id, 10),
                    context_id: query.context_id ? query.context_id : decoded.lti_context_id
                  },
                });
                if (check.data === true) {
                  setLoading(false);
                  const navigation_url = '/courselinking';
                  window.history.replaceState(null, '', navigation_url);
                  window.location.href = navigation_url;
                }
              } catch (e) {
                // 
              }
            }
          }
        }
      }
      setLoading(false);
      return decoded;
    }

    setLoading(false);
    removeRefreshToken();
    removeAccessToken();
    return null;
  };

  const clearCheckActivity = () => {
    if (checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current);
      checkIntervalRef.current = null;
    }
  };

  const initCheckActivity = async (userData = null) => {
    if ((!user && !userData) || process.env.NODE_ENV === 'test') {
      return;
    }

    clearCheckActivity();
    checkIntervalRef.current = setInterval(async () => {
      checkLastActivity();
    }, 30 * 1000);
  };

  const checkLastActivity = async (update = false, last = false) => {
    if (process.env.NODE_ENV === 'test') return true;
    if (query && query.user_id) return true;

    try {
      const response = await apiClient.get(v1.usage.checkLastActivity, {
        withCredentials: true,
        params: {
          update: update || activeRef.current,
        },
      });
      const { data } = response;

      if (activeRef.current) {
        activeRef.current = false;
        if (activeTimeRef.current) {
          clearTimeout(activeTimeRef.current);
        }
      }

      if (update) {
        // restart interval
        initCheckActivity();
      }

      if (data === -1) {
        // Show timeout modal
        setShowTimeout(true);
        clearCheckActivity();
      } else if (data === -2) {
        // Another session was started, force sign out
        await apiClient.post(v1.usage.sendUsage, {
          textBookComponentId: -1,
          templateId: -1,
          actionType: 11,
        });
        signOut(false);
        return false;
      } else if (data === 0) {
        // timeout expired, sign this user out
        await apiClient.post(v1.usage.sendUsage, {
          textBookComponentId: -1,
          templateId: -1,
          actionType: 12,
        });
        signOut(true);
        return false;
      }
      // if r == 1, some exception was raised
      else if (data === 1) {
        if (last) {
          initCheckActivity();
        }
      }
    } catch (e) {
      //
    }

    return true;
  };

  const updateActiveState = useCallback(
    _.debounce((active) => {
      activeRef.current = active;

      if (active) {
        setShowTimeout((prev) => {
          if (prev) {
            initCheckActivity();
            return false;
          }
          return prev;
        });

        if (activeTimeRef.current) {
          clearTimeout(activeTimeRef.current);
        }
        activeTimeRef.current = setTimeout(() => {
          activeRef.current = false;
        }, 30 * 1000);
      }
    }, 1000),
    [showTimeout],
  );

  const fetchUser = async () => {
    setLoading(true);
    try {
      const userRes = await apiClient.get(v1.account.me);
      if (userRes && userRes.data) {
        setUser(userRes.data);
        checkLastActivity();
        initCheckActivity(userRes.data);
      } else {
        setUser(null);
      }
    } catch (err) {
      signOut(false);
    }
    

    if (!isAddOn() && !isLTILaunch())
    {
      setLoading(false);
    }
  };

  const fetchLicenses = useCallback(async () => {
    try {
      const response = await apiClient.get(v1.licenses.list);

      const { data } = response;

      setLicenses(data);
    } catch (err) {
      // 
    }
  }, []);

  const fetchPreference = useCallback(async () => {
    try {
      const res = await apiClient.get(v1.account.preferences);
      setPreferences(res.data);
    } catch (e) {
      //
    }
  }, []);

  const signOut = async (clearSession = true) => {
    setLoading(false);
    const jwt = accessToken();
    setShowTimeout(false);
    clearCheckActivity();
    if (!jwt && !user) {
      return;
    }

    let classroom= false;
    if (isAddOn()) classroom = true;

    // erase session value and last activity time
    //
    // erasing a last activity from the server means it logges out all sessions.
    // unless we support 1. enumerating remote logins and 2. explicit "sign-out all" feature, clearSession should always be false.

    if (clearSession && jwt) {
      try {
        if (!apiClient.defaults.headers.common.Authorization) {
          apiClient.defaults.headers.common.Authorization = `Bearer ${jwt}`;
        }
        apiClient.put(v1.usage.updateLastActivity, {});
      } catch (err) {
        console.error(err);
      }
    }

    removeRefreshToken();
    removeAccessToken();
    setUser(null);

    if (classroom){
      window.parent.postMessage({
        type: 'Classroom',
        action: 'closeIframe',
      }, '*');
    }
  };

  const isAuthenticated = () => {
    if (typeof window === 'undefined') {
      return false;
    }

    if (window.location.pathname === '/lti-error') {
      return false;
    }

    const jwt = accessToken();
    if (!jwt) {
      if (query && query.courseId) return false;
      signOut(false);
    } else {
      return true;
    }
    return false;
  };

  const isAnatomageAdmin = () => user && user.account_type_id === 1;
  const isInstructor = () => (user && user.account_type_id === 3);
  const isStudent = () => user && user.account_type_id === 4;
  const isIndepAccount = () =>
    user && user.account_type_id === 4 && user.institution_id === -1;

  const isLTILaunch = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) {
      return safeJwtDecode(jwt).lti_platform && safeJwtDecode(jwt).lti_platform !== null;
    }

    return false;
  };

  const isAddOn = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) {
      return (safeJwtDecode(jwt).lti_platform && safeJwtDecode(jwt).lti_platform === 'Google Classroom');
    }

    if (query && (query.login_hint || query.itemId || query.attachmentId || query.courseId))
    {
      return true;
    }

    return false;
  };

  const isDeepLaunch = () => {
    const jwt = accessToken();

    if (jwt && safeJwtDecode(jwt) != null) {
      if (safeJwtDecode(jwt).DeepLaunch && safeJwtDecode(jwt).DeepLaunch === 'true') {
        return true;
      }
    }
    return false;
  };

  const LtiPlatform = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).lti_platform;
    return null;
  };

  const LtiContext = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).lti_context_id;
    return null;
  };

  const LtiUserId = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).lti_user_id;
    return null;
  };

  const LtiRegistrationId = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).lti_registration_id;
    return null;
  };

  const LtiDeploymentId = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).lti_deployment_id;
    return null;
  };

  const LtiLinkedClass = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return parseInt(safeJwtDecode(jwt).lti_linked_class, 10);
    return null;
  };

  const addOnToken = () => {
    const jwt = accessToken();

    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).add_on;
    return null;
  };

  const GoogleCourseId = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).lti_course_id;
    return null;
  };

  const GoogleLinkedClass = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).lti_context_id;
    return null;
  }

  const GoogleSubmissionId = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).submission_id;
    return null;
  }

  const GoogleItemId = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).item_id;
    return null;
  }

  const GoogleLoginId = () => {
    const jwt = accessToken();
    if (jwt && safeJwtDecode(jwt) != null) return safeJwtDecode(jwt).login_hint;
    return null;
  }

  const value = useMemo(
    () => ({
      loading,
      user,
      setUser,
      preferences,
      setPreferences,
      fetchPreference,
      initAccount,
      isAuthenticated,
      isAnatomageAdmin,
      isInstructor,
      isStudent,
      isIndepAccount,
      signOut,
      initCheckActivity,
      checkLastActivity,
      licenses,
      setLicenses,
      updateActiveState,
      isLTILaunch,
      isDeepLaunch,
      LtiPlatform,
      LtiUserId,
      LtiRegistrationId,
      LtiDeploymentId,
      LtiLinkedClass,
      validLtiLaunch,
      LtiContext,
      classLinked,
      setClassLinked,
      isAddOn,
      addOnToken,
      GoogleCourseId,
      GoogleLinkedClass,
      GoogleSubmissionId,
      GoogleItemId,
      GoogleLoginId
    }),
    [user, preferences, licenses, loading, accessToken, classLinked],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
