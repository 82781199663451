import { useRef, useEffect, useState } from 'react';
import { timeStringFormatter } from 'utilities/string-formatter';
import { useTranslation } from 'react-i18next';

import './box-graph.style.scss';

// time: all in seconds
export default function BoxGraph({ min, median, max, percentiles, time, enough }) {
  const ref = useRef();

  const [timeText, setTimeText] = useState({
    min: '',
    max: '',
    boxMin: '',
    boxMax: '',
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (ref.current) {
      // const totalLength = parseFloat(
      //   getComputedStyle(ref.current).getPropertyValue('width').replace('px', ''),
      // );

      const boxMin = percentiles[0];
      const boxMax = percentiles[1];
      const boxMinPct = ((percentiles[0] - min) / (max - min)) * 100;
      const boxMaxPct = ((percentiles[1] - min) / (max - min)) * 100;
      const boxAvg = ((median - min) / (max - min)) * 100;
      let textMin = min;
      let textMax = max;
      let textBoxMin = boxMin;
      let textBoxMax = boxMax;

      if (time) {
        if (min === max) {
          textMin = timeStringFormatter('0', false);
          textBoxMin = timeStringFormatter('0', false);
        } else {
          textMin =
            timeStringFormatter(min, true) || (percentiles[0] > 60 ? '0min' : '0sec');
          textBoxMin = timeStringFormatter(boxMin, true);
        }

        textMax = timeStringFormatter(max, true);

        textBoxMax = timeStringFormatter(boxMax, true);
      }

      setTimeText({
        min: textMin,
        max: textMax,
        boxMin: textBoxMin,
        boxMax: textBoxMax,
      });

      ref.current.style.setProperty('--box-min', `${boxMinPct}%`);
      ref.current.style.setProperty('--box-max', `${boxMaxPct}%`);
      ref.current.style.setProperty('--avg-pos', `${boxAvg}%`);
    }
  }, [min, max, percentiles, median, time]);

  return (
    <div className="box-graph-container" ref={ref}>
      {!enough && <div className="box-preview">{t('text.not-enough-data')}</div>}
      <div className="box-graph-canvas">
        <div className="box-graph-body">
          <div className="box-graph-rail-head">
            <div className="box-graph-rail" />
          </div>
        </div>
        {enough && timeText.boxMin.length > 0 && timeText.boxMax.length > 0 && (
          <>
            <div className="box-graph-box" />
            <div className="box-graph-avg-bar" />
          </>
        )}
      </div>
      {enough && (
        <>
          <div className="box-graph-min-text">{timeText.min}</div>
          <div className="box-graph-max-text">{timeText.max}</div>
        </>
      )}

      {enough && timeText.boxMin.length > 0 && (
        <div className="box-graph-box-min">
          <div className="box-graph-box-min-text">{timeText.boxMin}</div>
        </div>
      )}

      {enough && timeText.boxMax.length > 0 && (
        <div className="box-graph-box-max">
          <div className="box-graph-box-max-text">{timeText.boxMax}</div>
        </div>
      )}
    </div>
  );
}
