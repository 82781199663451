import { CheckBox } from 'components';
import { useTranslation, Trans } from 'react-i18next';

import './acknowledge.style.scss'

const AcknowledgeDialog = ({onChecked}) => {
  const { t } = useTranslation();

  return (
    <div className="acknowledge">
      <div className="acknowledge-check">
        <CheckBox value={false} onChecked={(res) => onChecked(res)} />
      </div>
      <div className="acknowledge-body">
        <Trans i18nKey="policy.privacy">
          ..
          <a
            href="https://www.anatomage.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            ..
          </a>
        </Trans>
      </div>
    </div>
  );
};

export default AcknowledgeDialog;
