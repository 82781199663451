import { React, useLayoutEffect, useRef } from 'react';


import './menulist-item.style.scss';

export default function MenuListItem({ icon, name, func, noPadding, autoHeight }) {
  const ref = useRef();
  const outer_ref = useRef();

  useLayoutEffect(() => {
    if (noPadding && ref.current){
      ref.current.style.setProperty("padding", "0px");
    }

    if (autoHeight && outer_ref.current){
      outer_ref.current.style.setProperty('height', "auto");
    }
  });

  return (
    <div
      ref={outer_ref}
      className="menulist-item-container"
      role="none"
      onClick={() => {
        func();
      }}
    >
      <div className="item-box" ref={ref}>
        {icon}
        {name}
      </div>
    </div>
  );
}
