import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { avatarColor, initial } from 'utilities/string-formatter';

import './avatar.style.scss';

const fontColor = (color) => {
  const root = getComputedStyle(document.documentElement);
  if (color === 'yellow') return root.getPropertyValue('--yellow-700');
  if (color === 'blue') return root.getPropertyValue('--blue-600');

  return root.getPropertyValue('--gray-600');
};

const backgroundColor = (color) => {
  const root = getComputedStyle(document.documentElement);
  if (color === 'yellow') return root.getPropertyValue('--yellow-200');
  if (color === 'blue') return root.getPropertyValue('--blue-200');

  return root.getPropertyValue('--slate-200');
};

export default function Avatar({ name, color, width, height, url }) {
  const ref = useRef();
  const avColor = color || avatarColor(name);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty('background-color', backgroundColor(avColor));

      const targetWidth = width;
      const targetHeight = height;

      // let fontSize = 12;
      // if (width <= 22) {
      //   fontSize = 10;
      // } else if (width > 30 && width < 40) {
      //   fontSize = 20;
      // } else if (width >= 40 && width < 100) {
      //   fontSize = 25;
      // } else if (width >= 100) {
      //   fontSize = 60;
      // }

      ref.current.style.setProperty('color', fontColor(avColor));
      ref.current.style.setProperty('width', `${targetWidth}px`);
      ref.current.style.setProperty('height', `${targetHeight}px`);
      // ref.current.style.setProperty('font-size', `${fontSize}px`);
    }
  }, [name, color, width, height]);

  return url ? (
    <img className="avartar-img" src={url} alt="" />
  ) : (
    <div ref={ref} className="avatar-base">
      {/* {email && (
        <div className="avatar-email">
          <ToolTip text={email} color="primary" />
        </div>
      )} */}
      {initial(name)}
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'yellow']), // without specify the color, it will generate from the initials
  width: PropTypes.number,
  height: PropTypes.number,
  url: PropTypes.string,
};
