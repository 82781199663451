import { useState, useMemo, useEffect, useCallback, useContext } from 'react';

import { apiClient } from 'common/api-client';
import { UserContext, NotificationContext } from 'contexts';
import { v1 } from 'common/apis';

export default function NotificationProvider({ children }) {
  const [updates, setUpdates] = useState();
  const [unreadAnnouncement, setUnreadAnnouncement] = useState({
    class: false,
    anatomage: false,
  });
  const [subjects, setSubjects] = useState([]);

  const { user, isInstructor } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      if (!isInstructor()) {
        fetchUnreadAnnouncement('class');
      }
      fetchUnreadAnnouncement('anatomage');
    }
    if (user) {
      fetchSubjects();
    }
  }, [user]);

  const fetchSubjects = useCallback(async () => {
    try {
      const res = await apiClient.get(v1.contents.subjects);
      setSubjects(res.data.data);
    } catch (e) {
      //
    }
  }, []);

  const fetchUnreadAnnouncement = useCallback(
    async (type) => {
      try {
        const res = await apiClient.get(v1.announcements.announcements, {
          params: {
            type,
            unread_only: true,
            page_size: 1,
          },
        });

        if (res && res.data) {
          const { data } = res.data;
          const exist =
            (type === 'class' && isInstructor() === false) || type === 'anatomage'
              ? data.length > 0
              : false;

          setUnreadAnnouncement((prev) => ({ ...prev, [type]: exist }));
        }
      } catch (e) {
        //
      }
    },
    [user],
  );

  const fetchUnreadAnnouncements = () => {
    if (!isInstructor()) {
      fetchUnreadAnnouncement('class');
    }

    fetchUnreadAnnouncement('anatomage');
  };

  const updateUnreadAnnouncement = useCallback((data) => {
    const anatomageFound = data.some((dt) => dt.class_id < 0 && !dt.read);
    const classFound = !isInstructor()
      ? data.some((dt) => dt.class_id > 0 && !dt.read)
      : false;
    setUnreadAnnouncement({ class: classFound, anatomage: anatomageFound });
  }, []);

  const value = useMemo(
    () => ({
      updates,
      setUpdates,
      unreadAnnouncement,
      setUnreadAnnouncement,
      subjects,
      updateUnreadAnnouncement,
      fetchUnreadAnnouncements,
      fetchSubjects
    }),
    [updates, subjects, unreadAnnouncement],
  );

  return (
    <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
  );
}
