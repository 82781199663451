import { useState, useMemo, useEffect } from 'react';

import CalendarContext from './calendar-context';

export default function CalendarProvider({ children }) {
  const [detailedItem, setDetailedItem] = useState();
  const [colWidth, setColWidth] = useState(0);

  useEffect(() => {
    calculateCalendarColumnWidth();
    window.addEventListener('resize', calculateCalendarColumnWidth, { passive: true });

    return () => {
      window.removeEventListener('resize', calculateCalendarColumnWidth);
    };
  }, []);

  const calculateCalendarColumnWidth = () => {
    const cols = document.getElementsByClassName('calendar-col');

    if (cols && cols.length > 0) {
      setColWidth(cols[0].clientWidth);
    }
  };

  const value = useMemo(
    () => ({
      colWidth,
      detailedItem,
      setDetailedItem,
    }),
    [colWidth, detailedItem],
  );

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
}
