import { useState, useMemo, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext, NavContext } from 'contexts';
import { APP_CONSTANTS } from 'common/strings';

export default function NavProvider({ children }) {
  const [current, setCurrent] = useState(APP_CONSTANTS.ROUTES.HOME);
  const location = useLocation();
  const { updateActiveState } = useContext(UserContext);
  useEffect(() => {}, [current]);

  useEffect(() => {
    updateActiveState(true);
  }, [location]);

  const value = useMemo(
    () => ({
      current,
      setCurrent,
    }),
    [current],
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
}
