import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './status.style.scss';

const StatusChip = ({ status }) => {
  const { t } = useTranslation();
  const ref = useRef();

  const [statusString, setStatusString] = useState(t('field.not-started'));

  useEffect(() => {
    if (status.includes('progress')) {
      setStatusString(t('field.in-progress'));
      ref.current.style.setProperty('background-color', 'var(--yellow-100)');
      ref.current.style.setProperty('color', 'var(--yellow-500)');
    } else if (status === 'done') {
      setStatusString(t('field.done'));
      ref.current.style.setProperty('background-color', 'var(--green-100)');
      ref.current.style.setProperty('color', 'var(--green-500)');
    } else if (status === 'active') {
      setStatusString(t('field.active'));
      ref.current.style.setProperty('background-color', 'var(--green-100)');
      ref.current.style.setProperty('color', 'var(--green-500)');
    } else if (status === 'inactive') {
      setStatusString(t('field.inactive'));
      ref.current.style.setProperty('background-color', 'var(--gray-200)');
      ref.current.style.setProperty('color', 'var(--gray-500)');
    }
  }, [status]);

  return (
    <div className="status-container" ref={ref}>
      {statusString}
    </div>
  );
};

StatusChip.propTypes = {
  status: PropTypes.oneOf([
    'in-progress',
    'progress',
    'done',
    'not-started',
    'active',
    'inactive',
  ]),
};

export default StatusChip;
