import { useMemo } from 'react';
import { XIcon, XCircleIcon } from 'resources/icons';

import './chip.style.scss';

export default function Chip({
  text,
  allowDelete,
  onDelete,
  func,
  type,
  small,
  fontSize,
}) {
  const className = useMemo(() => {
    let name = 'chip-container';
    if (type === 'lightweight') {
      name = `${name} lightweight`;
    } else if (type === 'gray') {
      name = `${name} gray-style`;
    }

    if (small) {
      name = `${name} small-chip`;
    }

    return name;
  }, [type, small]);

  return (
    <div
      className={className}
      role="none"
      onClick={() => {
        // func();
      }}
    >
      <div className="chip-text" style={{ fontSize }}>
        {text}
      </div>
      {allowDelete && (
        <div
          className="chip-delete-icon"
          role="none"
          onClick={(e) => {
            e.stopPropagation();
            if (onDelete) {
              onDelete();
            }
          }}
        >
          {small ? <XCircleIcon /> : <XIcon width="12px" height="12px" />}
        </div>
      )}
    </div>
  );
}
