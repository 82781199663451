import { useContext, useEffect, useMemo, useCallback, useRef } from 'react';
import { UserContext, NavContext, BarsContext, NotificationContext } from 'contexts';
import { APP_CONSTANTS } from 'common/strings';
import {
  HomeIcon,
  ClassIcon,
  AssignmentIcon,
  ContentIcon,
  CalendarIcon,
  AnnouncementIcon,
} from 'resources/icons';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import NavButton from './nav-button';

import './sidebar.style.scss';

export default function SideBar() {
  const { user, isAuthenticated, isDeepLaunch, loading } = useContext(UserContext);
  const { collapsed, setCollapsed } = useContext(BarsContext);
  const { unreadAnnouncement } = useContext(NotificationContext);
  const { t } = useTranslation();
  const ref = useRef();
  const bodyRef = useRef();
  const { current } = useContext(NavContext);
  const query = queryString.parse(window.location.search);

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (
        bodyRef.current &&
        !bodyRef.current.contains(event.target) &&
        event.target.className.baseVal !== 'hamburger-menu'
      ) {
        setCollapsed(true);
      }
    };
    if (window.innerWidth < 1135) {
      document.addEventListener('mousedown', checkOutsideClick);
    }
    return () => {
      if (window.innerWidth < 1135) {
        document.removeEventListener('mousedown', checkOutsideClick);
      }
    };
  }, [bodyRef]);

  const navButtons = useMemo(
    () => [
      {
        icon: <HomeIcon />,
        name: t('pages.home'),
        path: APP_CONSTANTS.ROUTES.HOME,
      },
      {
        icon: <ClassIcon />,
        name: t('pages.classes'),
        path: APP_CONSTANTS.ROUTES.CLASSES.BASE,
      },
      {
        icon: <AssignmentIcon />,
        name: t('pages.assignments'),
        path: APP_CONSTANTS.ROUTES.ASSIGNMENT.BASE,
      },
      {
        icon: <ContentIcon />,
        name: t('pages.contents'),
        path: APP_CONSTANTS.ROUTES.CONTENTS.BASE,
      },

      {
        icon: <CalendarIcon />,
        name: t('pages.calendar'),
        path: APP_CONSTANTS.ROUTES.CALENDAR,
      },

      {
        icon: <AnnouncementIcon />,
        name: (
          <div className="sidebar-item-wrapper">
            {t('pages.announcements')}
            {(unreadAnnouncement.class || unreadAnnouncement.anatomage) && (
              <div className="red-dot" />
            )}
          </div>
        ),
        path: APP_CONSTANTS.ROUTES.ANNOUNCEMENT.BASE,
      },
    ],
    [unreadAnnouncement],
  );

  const handleMediaQuery = useCallback(
    (type) => {
      const elem = ref.current;
      if (elem) {
        const elemStyle = getComputedStyle(elem);
        let width = elemStyle.getPropertyValue('--side-width');
        let display = 'flex';

        if (window.innerWidth < 1135) {
          if (type === 'hide') {
            width = '0px';
            display = 'none';
          } else if (type === 'show') {
            width = '100%';
          }
        }

        elem.style.setProperty('display', display);
        elem.style.setProperty('width', width);
      }
    },
    [ref],
  );

  useEffect(() => {
    const tabletHandler = (e) => {
      handleMediaQuery(e.matches ? 'hide' : '');
      setCollapsed(e.matches);
    };
    // const handler = (e) => {
    //   handleMediaQuery('hide');
    //   if (e.matches) {
    //     setCollapsed(true);
    //   }
    // };
    // setMobile(window.matchMedia('(max-width: 420px)')?.matches === true);

    window.matchMedia('(max-width: 1135px)')?.addEventListener('change', tabletHandler);
    // window.matchMedia('(max-width: 420px)')?.addEventListener('change', handler);
    return () => {
      window
        .matchMedia('(max-width: 1135px)')
        ?.removeEventListener('change', tabletHandler);
      // window.matchMedia('(max-width: 420px)')?.removeEventListener('change', handler);
    };
  }, []);

  useEffect(() => {
    // clicking hamburger menu
    handleMediaQuery(collapsed ? 'hide' : 'show');
  }, [collapsed]);

  // Hide side-bar in deep link content selection
  if (!user || !isAuthenticated() || isDeepLaunch() || query.google_courselink || loading) return null;

  return (
    <div className="sidebar-container" ref={ref}>
      <div className="sidebar-body" ref={bodyRef}>
        {navButtons.map((nb, index) => {
          const selected = nb.path === current;
          return <NavButton key={`nav-btn-${index}`} {...{ ...nb, selected }} />;
        })}
      </div>
    </div>
  );
}
