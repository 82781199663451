import { useEffect, useMemo } from 'react';

import './icon-button.style.scss';

export default function IconButton({ icon, kind, noPadding, sx, onClick }) {
  const className = useMemo(() => {
    let name = 'icon-button';

    if (kind === 'secondary') {
      name += ' secondary';
    } else if (kind === 'icon-only') {
      name += ' icon-only';
    }

    if (sx === 'medium') {
      name += ' icon-medium';
    } else if (sx === 'xsmall') {
      name += ' icon-xsmall';
    } else if (sx === 'small') {
      name += ' icon-small';
    }

    if (noPadding) {
      name += ' no-padding';
    }

    return name;
  }, [sx, kind, noPadding]);

  return (
    <div
      className={className}
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {icon}
    </div>
  );
}
