import { useState, useMemo, useContext } from 'react';
import moment from 'moment';
import { dateToISOString } from 'utilities/timestring';
import { DatePickerContext, DatePickerProvider } from 'contexts';
import CreateEditClassContext from './create-class-context';

export default function CreateEditClassProvider({ children }) {
  return (
    <DatePickerProvider nextDay>
      <ClassProviderMain>{children}</ClassProviderMain>
    </DatePickerProvider>
  );
}

function ClassProviderMain({ children }) {
  const [classId, setClassId] = useState(-1);
  const [className, setClassName] = useState('');
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [license, setLicense] = useState([]);

  const [allocatedSeats, setAllocatedSeats] = useState(null);
  const [filledSeats, setFilledSeats] = useState(null);
  const { dates, setDates } = useContext(DatePickerContext); // start and end date
  const [students, setStudents] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const resetContext = () => {
    setClassId(-1);
    setClassName('');
    setDaysOfWeek([]);
    setLicense([]);
    setAllocatedSeats(null);
    setFilledSeats(null);
    setDates([dateToISOString(moment()), dateToISOString(moment().add(1, 'day'))]);
    setStudents([]);
    setIsEdit(false);
  };

  const value = useMemo(
    () => ({
      className,
      setClassName,
      dates,
      setDates,
      daysOfWeek,
      setDaysOfWeek,
      license,
      setLicense,
      allocatedSeats,
      setAllocatedSeats,
      filledSeats,
      setFilledSeats,
      students,
      setStudents,
      classId,
      setClassId,
      isEdit,
      setIsEdit,
      resetContext,
    }),
    [className, classId, dates, allocatedSeats, license, isEdit, daysOfWeek],
  );

  return (
    <CreateEditClassContext.Provider value={value}>
      {children}
    </CreateEditClassContext.Provider>
  );
}
