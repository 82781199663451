import { useState, useMemo } from 'react';

import BarsContext from './bar-context';

export default function BarsProvider({ children }) {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1135);

  const value = useMemo(
    () => ({
      collapsed,
      setCollapsed,
    }),
    [collapsed],
  );

  return <BarsContext.Provider value={value}>{children}</BarsContext.Provider>;
}
