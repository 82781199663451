import moment from 'moment';

export const dateToISOString = (date, end) => {
  const converted = moment()
    .set('year', date.get('years'))
    .set('month', date.get('month'))
    .set('dates', date.get('dates'));

  if (end) {
    return `${converted.format('YYYY-MM-DD')}T23:59:59.000Z`;
  }

  return `${converted.format('YYYY-MM-DD')}T00:00:00.000Z`;
};

export const isBefore = (date, comp) => {
  if (date.get('year') < comp.get('year')) {
    return true;
  }

  if (date.get('year') > comp.get('year')) {
    return false;
  }

  if (date.get('month') < comp.get('month')) {
    return true;
  }

  if (date.get('month') > comp.get('month')) {
    return false;
  }

  if (date.get('dates') < comp.get('dates')) {
    return true;
  }

  if (date.get('dates') > comp.get('dates')) {
    return false;
  }

  return false;
};

export const duration = (start, end) => {
  const startDate = moment.utc(start);
  const endDate = moment.utc(end);

  if (startDate.year() === endDate.year()) {
    return `${startDate.format('MMM DD')} - ${endDate.format('MMM DD, YYYY')}`;
  }

  return `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`;
};
