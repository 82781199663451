import { useContext } from 'react';
import { BarsContext } from 'contexts';
import { useNavigate } from 'react-router-dom';
import './nav-button.style.scss';

export default function NavButton(props) {
  const { icon, name, path, selected } = props;
  const { setCollapsed } = useContext(BarsContext);
  const navigate = useNavigate();

  return (
    <div
      className={`nav-btn-container ${selected ? 'active' : ''}`}
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        if (window.innerWidth < 1135) {
          setCollapsed(true);
        }
        navigate(path);
      }}
    >
      {icon}
      {name}
    </div>
  );
}
