import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import lottie from 'lottie-web';

import { useTranslation } from 'react-i18next';
import BasePage from 'common/base-page/base-page';
import { Button } from 'components';
import { APP_CONSTANTS } from 'common/strings';
import './stud-signup-congrats.style.scss';

export default function SignUpCongrats({ title, subtitle, customWidth = 320, buttonText=null, buttonUrl=APP_CONSTANTS.ROUTES.SIGNIN}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const ref = useRef();
  const lottieRef = useRef();

  useEffect(() => {
    if (customWidth !== 320)
      ref.current.style.setProperty('width', `${customWidth.toString()}px`);
  }, []);

  useEffect(() => {
    lottie.destroy('congrats');
    const instance = lottie.loadAnimation({
      name: 'congrats',
      container: lottieRef.current,
      renderer: 'canvas',
      loop: true,
      autoplay: true,
      // eslint-disable-next-line global-require
      animationData: require('resources/animation/congratulation.json'),
    });

    // Return clean up function here
    return () => instance.destroy();
  }, [lottieRef.current]);

  return (
    <BasePage footer={false} marginBottom>
      <div className="congratulation-animation" ref={lottieRef} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate(buttonUrl);
        }}
      >
        <div className="box-section">
          <h2 className="box-title congrats-title" ref={ref}>
            {title}
          </h2>
          <div className="congrats-subtitle">{subtitle}</div>
        </div>

        <div className="stud-congrats-buttons">
          <Button
            type="submit"
            kind="primary"
            sx="large"
            content={buttonText || t('button.go-to-sign-in')}
          />
        </div>
      </form>
    </BasePage>
  );
}
