import { useEffect, useState, useMemo, useContext, useCallback, useRef } from 'react';
import moment from 'moment';
import { DatePickerContext } from 'contexts';
import { dateToISOString, isBefore } from 'utilities/timestring';
import { days } from 'common/strings';
import { isMobileDevice } from 'utilities/validation';
import {
  ExpandIcon,
  CollapseIcon,
  ChevronRightSmallIcon,
  ChevronLeftIcon,
} from 'resources/icons';

import YearAndMonthSelection from './year-month-picker';

import './datepicker-calendar.style.scss';

export default function DatePickerCalendar({
  value,
  isStart,
  onPicked,
  disablePast = false,
}) {
  const [candidates, setCandidates] = useState([]);
  const [date, setDate] = useState();
  const [openYearNav, setOpenYearNav] = useState(false);
  const { dates } = useContext(DatePickerContext);

  const ref = useRef();

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.tagName !== 'path'
      ) {
        onPicked(value);
      }
    };
    if (!isMobileDevice()) document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      if (!isMobileDevice()) document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, [ref]);

  useEffect(() => {
    if (value) {
      setDate(moment.utc(value));
    }
  }, [value]);

  useEffect(() => {
    if (date) {
      const month = date.month();
      const generatedDates = Array.from(
        { length: moment.utc(`${date.year()}/${month + 1}/01`).daysInMonth() },
        (x, i) => date.clone().startOf('month').add(i, 'days'),
      );

      setCandidates(generatedDates);
    }
  }, [date]);

  const gap = useMemo(() => {
    if (candidates.length > 0) {
      return candidates[0].day();
    }
    return 0;
  }, [candidates]);

  const shouldDisabled = useCallback(
    (colDate) => {
      if (!isStart && date) {
        return (
          colDate.format('YYYY-MM-DD') !== moment.utc(dates[0]).format('YYYY-MM-DD') &&
          isBefore(colDate, moment.utc(dates[0]))
        );
      }
      if (isStart && disablePast) {
        return isBefore(colDate.startOf('day'), moment().startOf('day'));
      }
      return false;
    },
    [isStart, dates, date, disablePast],
  );

  return (
    <div
      ref={ref}
      className={`dp-calendar-container ${isMobileDevice() ? 'mobile-format' : ''}`}
      role="none"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="dp-calendar-control">
        <div
          className="dp-calendar-setting"
          role="none"
          onClick={(e) => {
            e.stopPropagation();
            setOpenYearNav((prev) => !prev);
          }}
        >
          <div className="dp-calendar-month">{date?.format('MMMM YYYY')}</div>
          <div className="dp-calendar-modify-btn">
            {openYearNav ? (
              <CollapseIcon className="dp-expander-icon" />
            ) : (
              <ExpandIcon className="dp-expander-icon" />
            )}
          </div>
        </div>
        {!openYearNav && (
          <div className="dp-calendar-monthly-change-btn">
            <ChevronLeftIcon
              className="dp-navigator"
              role="none"
              onClick={(e) => {
                e.stopPropagation();
                setDate((prev) => prev.clone().subtract(1, 'month'));
              }}
            />
            <ChevronRightSmallIcon
              className="dp-navigator"
              role="none"
              onClick={(e) => {
                e.stopPropagation();
                setDate((prev) => prev.clone().add(1, 'month'));
              }}
            />
          </div>
        )}
      </div>
      {!openYearNav ? (
        <div className="dp-calendar-body-wrapper">
          <div className="dp-calendar-day-row">
            {days.map((dy, index) => (
              <div className="dp-calendar-col" key={`days-key${index}`}>
                {dy}
              </div>
            ))}
          </div>
          {candidates.length > 0 &&
            [...Array(6)].map((_, index) => {
              const maxLength = candidates.length + gap;
              if (index > 4 && maxLength < 36) {
                return null;
              }

              return (
                <div className="dp-calendar-row" key={`dp-calendar-row${index}`}>
                  {[...Array(7)].map((_, idx) => {
                    const curCol = idx + index * 7;
                    const isPriorMonth = curCol < gap;
                    const isNextMonth = candidates.length <= curCol - gap;

                    const calDate =
                      isPriorMonth || isNextMonth
                        ? date.clone()
                        : candidates[curCol - gap].clone();
                    if (isPriorMonth) {
                      calDate.subtract(1, 'month');
                      calDate.endOf('month').subtract(gap - curCol - 1, 'days');
                    } else if (isNextMonth) {
                      calDate.add(1, 'month');
                      calDate

                        .startOf('month')
                        .add(curCol - (gap + candidates.length), 'day');
                    }

                    const disabled = shouldDisabled(calDate);

                    const compareDate = isStart
                      ? moment.utc(dates[0])
                      : moment.utc(dates[1]);

                    const requiredHighlight =
                      compareDate.format('YYYY-MM-DD') === calDate.format('YYYY-MM-DD');

                    return (
                      <div
                        className={`dp-calendar-col ${
                          isPriorMonth || isNextMonth ? 'not-this-month' : ''
                        } ${disabled ? 'disabled-dp-calendar-col' : ''}`}
                        key={`dp-calendar-col${idx}`}
                      >
                        <div
                          className={`dp-calendar-pointer ${
                            date.format('YYYY-MM-DD') === calDate.format('YYYY-MM-DD')
                              ? 'date-picked'
                              : ''
                          } ${requiredHighlight ? 'date-highlight' : ''}`}
                          role="none"
                          onClick={(e) => {
                            e.stopPropagation();

                            if (!disabled) {
                              onPicked(dateToISOString(calDate));
                            }
                          }}
                        >
                          {calDate.date().toString()}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      ) : (
        <YearAndMonthSelection
          mobile={isMobileDevice()}
          date={date}
          onSelected={(res) => {
            setDate(res);
            setOpenYearNav(false);
          }}
        />
      )}
    </div>
  );
}
