import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './drag-and-drop.style.scss';

const DragAndDrop = ({ onFileUpload }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const handleDragOver = (event) => {
      event.preventDefault();
    };
  
    const handleDrop = (event) => {
      event.preventDefault();
      const droppedFile = event.dataTransfer.files[0]; 
        
      // Check if the file type is valid
      if (isValidFileType(droppedFile)) {
        setFile(droppedFile);
        setError('');
        onFileUpload(droppedFile); 
      } else {
        setError(t('error.invalid-file-type'));
        onFileUpload(null); 
        setFile(null);
      }
    };

    const isValidFileType = (file) => (
        file && (file.name.endsWith('.vpf') || file.name.endsWith('.vpfc'))
    );
  
    const handleFileSelect = (event) => {
      const selectedFile = event.target.files[0];

      // Check if the file type is valid
      if (isValidFileType(selectedFile)) {
        setFile(selectedFile);
        setError('');
        onFileUpload(selectedFile); 
      } else {
        setError(t('error.invalid-file-type'));
        onFileUpload(null); 
        setFile(null);
      }
    };

    return (
      <div>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className='uploadBox'
        >
          
          <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
            {file ? (
                <p>
                    Drag and drop to change or <strong>click to select another file</strong>.
                    <br />
                    (.vpf, .vpfc)
                    <br />
                    <br />
                    <strong>{file.name}</strong> 
                </p>
            ) : (
                <p>
                    Drag and drop a file here, or <strong>click to select</strong>
                    <br />
                    (.vpf, .vpfc)
                </p>
            )}
            <input 
                type="file" 
                accept=".vpf, .vpfc" // Restrict accepted file types
                onChange={handleFileSelect}
                style={{ display: 'none' }} 
                id="fileInput"
            />
          </label>
        </div>
        {error && <p className="uploadError"> {error}</p>}
      </div>
    );
  };
  
  export default DragAndDrop;