import { useState, useMemo } from 'react';

import InstitutionContext from './create-institution-context';

export default function InstitutionProvider({ children }) {
  const [InstitutionName, setInstitutionName] = useState('');
  const [address, setAddress] = useState(''); // start and end date
  const [country, setCountry] = useState('');
  const [salesForceUrl, setSalesForceUrl] = useState('');
  const [salesForceAccountId, setSalesForceAccountId] = useState('');

  const value = useMemo(
    () => ({
      address,
      setAddress,
      country,
      setCountry,
      salesForceUrl,
      setSalesForceUrl,
      salesForceAccountId,
      setSalesForceAccountId,
      InstitutionName,
      setInstitutionName,
    }),
    [address, country, salesForceUrl, salesForceAccountId, InstitutionName],
  );

  return (
    <InstitutionContext.Provider value={value}>{children}</InstitutionContext.Provider>
  );
}
