import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './dialog.style.scss';

const Dialog = ({ open, showModal, children }) => {
  const ref = useRef();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      if (showModal) {
        ref.current.showModal();
      } else {
        ref.current.close();
      }
    }
  }, [showModal]);

  return (
    <dialog open={open} className="eb-dialog" ref={ref}>
      {children}
    </dialog>
  );
};
Dialog.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool,
  showModal: PropTypes.bool,
};

export default Dialog;
