/* eslint-disable no-nested-ternary */
import { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PageLoading from 'components/loading/page-loading';
import { CheckBox, Tooltip } from 'components';

import './table.style.scss';
import { TooltipIcon } from 'resources/icons';

/**
 * Table can be accessed from the parent compnent by using ref to call functions that are defined in the useImperativeHandle of the component
 */
const Table = forwardRef(
  (
    {
      id,
      loading,
      headers,
      data,
      selectable,
      includeId,
      tableStyle,
      tdStyle,
      theadStyle,
      paginationRef,
      emptyState,
    },
    ref,
  ) => {
    const [selected, setSelected] = useState([]);
    const { t } = useTranslation();

    const handleCheckAll = (checked) => {
      if (checked) {
        setSelected(data.map((dt) => dt.id));
      } else {
        setSelected([]);
      }
    };

    useImperativeHandle(ref, () => ({
      selectedItems() {
        return selected;
      },
      clearSelection() {
        setSelected([]);
      },
    }));

    return (
      <div>
        <table
          id={id}
          className={`eb-table ${emptyState ? 'empty' : ''}`}
          style={tableStyle}
        >
          <thead style={theadStyle} className={`${emptyState ? 'empty' : ''}`}>
            <tr className={`eb-table-header ${data.length < 1 ? 'no-data' : ''}`}>
              {selectable && (
                <td className="select-checkbox">
                  <CheckBox
                    value={data.length > 0 && data.length === selected.length}
                    onChecked={(res) => {
                      handleCheckAll(res);
                    }}
                  />
                </td>
              )}
              {headers?.map((hd, index) => (
                <td key={`table-header${index}`}>
                  <div style={{ display: 'flex' }}>
                    <div className="table-col">
                      {hd.title}
                      {
                        // TODO: remove this and pass the component itself
                        hd.title === t('field.quiz-accuracy') ? (
                          <Tooltip text={t('text.quiz-tooltip')}>
                            <TooltipIcon />
                          </Tooltip>
                        ) : null
                      }
                      {
                        // TODO: remove this and pass the component itself
                        hd.title === t('field.threed-quiz-completion') ? (
                          <Tooltip text={t('text.threed-completion-tooltip')}>
                            <TooltipIcon />
                          </Tooltip>
                        ) : null
                      }
                      {
                        // TODO: remove this and pass the component itself
                        hd.title === t('field.threed-quiz-points') ? (
                          <Tooltip text={t('text.threed-points-tooltip')}>
                            <TooltipIcon />
                          </Tooltip>
                        ) : null
                      }
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          </thead>

          <tbody>
            {!emptyState || !emptyState.obj ? (
              data?.map((dt, index) => (
                <tr key={`table-body-item${index}`} className="table-body-item">
                  {selectable && (
                    <td className="select-checkbox">
                      <CheckBox
                        value={selected.includes(dt.id)}
                        onChecked={(res) => {
                          if (res) {
                            setSelected([...selected, dt.id]);
                          } else {
                            setSelected((prev) => prev.filter((si) => si !== dt.id));
                          }
                        }}
                      />
                    </td>
                  )}
                  {Object.keys(dt).map((dtKey, tdindex) => {
                    if (!includeId && dtKey === 'id') return null;

                    if (dtKey === 'obj') return null;

                    if (dt[dtKey] === null) return null;

                    return (
                      <td style={tdStyle} key={`td-key${tdindex}`}>
                        {dt[dtKey]}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length}>
                  <div className="center-aligned-item">{emptyState.obj} </div>
                </td>
              </tr>
            )}
            {loading && (
              <tr>
                <td colSpan={headers.length}>
                  <div className="center-aligned-item">
                    <PageLoading />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
        <div ref={paginationRef} style={{ visibility: 'collapse' }} />
      </div>
    );
  },
);

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired }))
    .isRequired,
  selectable: PropTypes.bool,
  includeId: PropTypes.bool, // this will display the id if the data object includes the key id
  tableStyle: PropTypes.shape({ tableLayout: PropTypes.string }),
  tdStyle: PropTypes.shape({
    overflowHidden: PropTypes.string,
    textOverflow: PropTypes.string,
  }),
  theadStyle: PropTypes.shape({
    position: PropTypes.string,
    top: PropTypes.number,
    backgroundColor: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  paginationRef: PropTypes.any,
  emptyState: PropTypes.shape({
    empty: PropTypes.bool,
  }),
};

export default Table;
