import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CopyRight, Drawer } from 'components';
import TermsOfUse from 'pages/terms-of-use/terms-of-use';

import './base-page.style.scss';

export default function AuthBase({ footer = true, marginBottom = false, children }) {
  const [openTerms, setOpenTerms] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="auth-base-container">
      {openTerms && (
        <Drawer
          title={t('pages.anatomage-terms-of-use')}
          showCloseButton
          onClose={() => {
            setOpenTerms(false);
          }}
        >
          <TermsOfUse />
        </Drawer>
      )}
      <div className={`box-container ${marginBottom ? 'marginBottom' : ''}`}>
        {children}
      </div>
      {footer && (
        <>
          <div className="auth-base-description">
            <Trans i18nKey="policy.cookies">
              Please confirm your device meets
              <a
                href="https://www.anatomage.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                the System Requirements.
              </a>
              <span
                className="page-link"
                role="none"
                onClick={() => {
                  setOpenTerms(true);
                }}
              >
                Terms of Use
              </span>
            </Trans>
          </div>
          <CopyRight />
        </>
      )}
    </div>
  );
}
