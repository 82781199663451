import { useState, useEffect, useMemo, useContext } from 'react';
import { DatePickerProvider } from 'contexts';

import CreateEditAssignmentContext from './create-assignment-context';

export default function CreateEditAssignmentProvider({ children }) {
  return (
    <DatePickerProvider>
      <CreateEditAssignmentProviderMain>{children}</CreateEditAssignmentProviderMain>
    </DatePickerProvider>
  );
}

function CreateEditAssignmentProviderMain({ children }) {
  const [assignmentId, setAssignmentId] = useState('');
  const [assignmentName, setAssignmentName] = useState('');
  const [classes, setClasses] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [removeQuizzes, setRemoveQuizzes] = useState([]);


  const value = useMemo(
    () => ({
      assignmentId,
      setAssignmentId,
      classes,
      setClasses,
      materials,
      setMaterials,
      assignmentName,
      setAssignmentName,
      quizzes,
      setQuizzes,
      removeQuizzes,
      setRemoveQuizzes
    }),
    [classes, materials, assignmentName, assignmentId, quizzes, removeQuizzes],
  );

  return (
    <CreateEditAssignmentContext.Provider value={value}>
      {children}
    </CreateEditAssignmentContext.Provider>
  );
}
