import { useState, useRef, useEffect, useLayoutEffect, useId } from 'react';
import { SearchIcon, XIcon } from 'resources/icons';
import { useTranslation } from 'react-i18next';

import './search.style.scss';

export default function SearchBar({ placeholder, noShrink, onSearch, size, value }) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const ref = useRef();
  const searchId = useId();

  useEffect(() => {
    setSearchString(value || '');
  }, [value]);

  useEffect(() => {
    if (ref.current && size) {
      ref.current.style.setProperty('width', `${size.width}px`);
      ref.current.style.setProperty('height', `${size.height}px`);
    }
  }, [ref, size]);

  useLayoutEffect(() => {
    if (noShrink) {
      setExpanded(true);
    }
  }, [noShrink]);

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.tagName !== 'path' &&
        !searchString
      ) {
        setExpanded(false);
      }
    };
    if (!noShrink) {
      document.addEventListener('mousedown', checkOutsideClick);
    }
    return () => {
      if (!noShrink) {
        document.removeEventListener('mousedown', checkOutsideClick);
      }
    };
  }, [ref, searchString, noShrink]);

  return (
    <div className={`search-container ${expanded ? 'expanded-style' : ''}`} ref={ref}>
      {!expanded ? (
        <SearchIcon
          role="none"
          onClick={(e) => {
            e.stopPropagation();
            if (!noShrink) {
              setExpanded(true);
            }
          }}
        />
      ) : (
        <div className="search-box">
          <SearchIcon className="search-icon" width="24px" height="24px" />
          <input
            id={searchId}
            type="text"
            value={searchString}
            className="search-input"
            placeholder={placeholder || t('menu.search')}
            onChange={(e) => {
              setSearchString(e.target.value);
              if (e.target.value === '') {
                onSearch('');
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch(searchString);
              }
            }}
          />
          {searchString && (
            <XIcon
              role="none"
              width="16px"
              height="16px"
              onClick={() => {
                setSearchString('');
                onSearch('');
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
