/* eslint-disable global-require */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import ReactCodeInput from 'react-code-input';
import { apiClient } from 'common/api-client';
import { v1 } from 'common/apis';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Spinner from 'components/loading/spinner';

import './confirm-email.style.scss';

const EmailCode = ({ email, goNext, goBack, resend }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [btnPressed, setBtnPressed] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [helpModal, setHelpModal] = useState(false);
  const [message, setMessage] = useState("Checking our inbox ...");
  const [resent, setResent] = useState(false);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
        .withUrl('https://api.anatomagelessons.com/notificationHub')
        .configureLogging(LogLevel.None) // Disable logging
        .build();

    connection.on('UserVerified', (userId) => {
        connection.stop();
        goNext();
    });

    connection.start()
      .then(async () => {

        const { connectionId } = connection

        if (connectionId) {
            await apiClient.post(v1.account.set_connection_id, { email, id: connectionId });
        } else {
            // console.error('Connection ID is null after establishing connection.');
        }
      })
      .catch(err => 
        // console.error('Error connecting to SignalR hub:', err)
        console.error('')
      )

    connection.onclose(async () => {
        setHelpModal(false);
    });

    return () => {
        connection.stop();
    };
}, []);

useEffect(() => {
  const interval = setInterval(() => {
    setFade(false); // Fade out before changing message
    setTimeout(() => {
      setMessage((prevMessage) =>
        prevMessage === "Checking our inbox ..." 
          ? "This may take a few minutes ..." 
          : "Checking our inbox ..."
      );
      setFade(true); 
    }, 750); // Time for fade-out effect
  }, 5000); // Change every 5 seconds

  return () => clearInterval(interval);
}, []);


  const handleCodeChange = (code) => {
    setCode(code);
    setBtnPressed(false);
  };

  const checkCode = async () => {
    if (code < 99999) {
      setIsCodeValid(false);
      setBtnPressed(true);
      setCode('');
    } else {

      try {
        await apiClient.get(v1.account.get_verification_code, {params: {email, otp: code}});
        setBtnPressed(true);
        setIsCodeValid(true);
        goNext();
      }
      catch {
        setBtnPressed(true);
        setIsCodeValid(false);
        setCode('');
      }
    }
  };

  return (
    <div className="box-section">
      <h2 className="box-title email-code-title">{helpModal ? t('pages.email-us') : t('pages.check-inbox')}</h2>

      <div className="email-code-dialog-box">
        {helpModal && 
          <>
            <div className="email-code-subtitle">
              <div dangerouslySetInnerHTML={{ __html: t('text.manual-verification', {email}) }} />
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", flexDirection: "column"}}>
              <Spinner shape='outlined' sx='small' /> 
              <div className={`fade-message ${fade ? 'fade-in' : 'fade-out'}`} >
                {message}
              </div>
            </div>
          </>
        }
        {!helpModal &&
          <>
            <div className="email-code-subtitle">
              {t('text.check-inbox-body', {email})}
            </div>
            <ReactCodeInput
              type="number"
              fields={6}
              isValid={isCodeValid}
              onChange={handleCodeChange}
              value={code}
            />
          </>
        }
        <div>
          <Button
            type="submit"
            kind="primary"
            sx="large"
            content="Enter"
            onClick={() => checkCode()}
            disabled={btnPressed || helpModal}
          />
          <div style={{marginTop: "5px"}}>
            <Button
              type="submit"
              kind="secondary"
              sx="xsmall"
              shape="text"
              content="Resend digit code"
              disabled={resent}
              onClick={()=> {resend(); setResent(true);}}
            />
          </div>
          {resent &&
            <div style={{marginTop: "5px"}}>
              <Button
                type="submit"
                kind="secondary"
                sx="xsmall"
                shape="text"
                content="Trouble receiving email?"
                disabled={helpModal}
                onClick={()=> {setHelpModal(true);}}
              />
            </div>
          }
          <br/>
          <Button
            type="submit"
            kind="secondary"
            sx="xsmall"
            shape="text"
            content="Back"
            onClick={()=> goBack()}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailCode;
