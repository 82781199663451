import './copyright.style.scss';

export default function CopyRight() {
  return (
    <div className="copyright-container">
      <a className="copyright-link" href="https://www.anatomage.com/">
        {`Copyright © ${new Date()
          .getFullYear()
          .toString()} Anatomage, All rights reserved.`}
      </a>
    </div>
  );
}
