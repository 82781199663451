import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import App from './App';
import reportWebVitals from './reportWebVitals';

let msalInstance;
try {
  msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.initialize();
  msalInstance.enableAccountStorageEvents();
} catch (error) {
    console.error('MSAL initialization error:', error);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
if (msalInstance) {
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance} >
        <App />
      </MsalProvider>
    </React.StrictMode>
  ,
  );
}
else {
  root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
  ,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
