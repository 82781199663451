import { useId } from 'react';
import { XIcon } from 'resources/icons';

import './field-button.style.scss';

export default function FieldWithButton({
  label,
  value,
  search,
  sx,
  onFocus,
  onBlur,
  placeholder,
  onChange,
  onClick,
  disabled,
  buttonDisabled,
  buttonLabel,
  buttonIcon,
  errorMsg,
}) {
  const inputId = useId();

  return (
    <div className="field-button-container">
      {label && <div className="field-button-label">{label}</div>}
      <div className={`field-button-wrapper ${errorMsg ? 'error' : ''}`}>
        <div className="input-wrapper">
          <input
            id={inputId}
            type="text"
            style={{ padding: sx === 'medium' ? '8px 12px' : '12px 16px' }}
            disabled={disabled}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClick();
              }
            }}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
          {search && value && value.length > 0 && (
            <div
              className="reset-search-btn"
              disabled={disabled}
              role="none"
              onClick={(e) => {
                e.stopPropagation();
                onChange('');
              }}
            >
              <XIcon width="15px" height="15px" />
            </div>
          )}
        </div>
        <button
          disabled={buttonDisabled}
          className="textfield-button-button"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {buttonLabel || buttonIcon}
        </button>
      </div>
      {errorMsg && <div className="field-button-error">{errorMsg}</div>}
    </div>
  );
}
