import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CollapseIcon, ExpandIcon } from 'resources/icons';
import { CheckBox, MobileDialog } from 'components';

import './dropdown-with-checkbox.style.scss';
import { isMobileDevice } from 'utilities/validation';

export default function DropDownWithCheckBox({
  placeholder,
  selected,
  type,
  items,
  onSelected,
  zindex,
}) {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.tagName !== 'path'
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, [ref]);

  const handleCheck = (checked, item) => {
    if (checked) {
      onSelected([...selected, item]);
    } else {
      const filtered = selected.filter((sl) => sl.id !== item.id);
      onSelected(filtered);
    }
  };

  return (
    <div
      ref={ref}
      className={`ddc-container ${zindex}`}
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open);
      }}
    >
      <div
        className={`ddc-placeholder ${selected && selected.length > 0 ? 'selected' : ''}`}
      >
        {selected && selected.length > 0
          ? selected
              .map((sel) => (type === 'day' ? sel.text.substring(0, 3) : sel.text))
              .join(',')
          : placeholder}
      </div>
      {open ? <CollapseIcon className="ddc-icon" /> : <ExpandIcon className="ddc-icon" />}

      {open &&
        (isMobileDevice() ? (
          <MobileDialog
            scrollRef={ref}
            onClose={() => {
              setOpen(false);
            }}
          >
            <ChecboxList items={items} selected={selected} handleCheck={handleCheck} />
          </MobileDialog>
        ) : (
          <div className="ddc-items">
            <ChecboxList items={items} selected={selected} handleCheck={handleCheck} />
          </div>
        ))}
    </div>
  );
}

const ChecboxList = ({ items, selected, handleCheck }) =>
  items.map((item, index) => (
    <div
      key={`dp-item${index}`}
      className="ddc-item"
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        const currentChecked = !!selected.find((st) => st.id === item.id);
        handleCheck(!currentChecked, item);
      }}
    >
      <CheckBox
        value={!!selected.find((st) => st.id === item.id)}
        onChecked={(checked) => {
          handleCheck(checked, item);
        }}
      />
      <div className="ddc-item-texts">
        <div className="ddc-item-text">{item.text}</div>
        {item.subText && <div className="ddc-item-sub-text"> {item.subText} </div>}
      </div>
    </div>
  ));

DropDownWithCheckBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.string,
    }),
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.string,
    }),
  ).isRequired,
  onSelected: PropTypes.func.isRequired,
  zindex: PropTypes.string,
};
