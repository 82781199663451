/* eslint-disable global-require */
import { useEffect, useMemo, useRef } from 'react';
import lottie from 'lottie-web';

export default function Spinner({ id, shape, sx }) {
  const containerRef = useRef();
  useEffect(() => {
    lottie.destroy(id || 'spinner');
    lottie.loadAnimation({
      name: id,
      container: document.getElementById(id || 'spinner'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData:
        shape === 'outlined' || !shape
          ? require('resources/animation/spinner-blue.json')
          : require('resources/animation/spinner-white.json'),
    });
  }, []);

  const styles = useMemo(() => {
    if (sx === 'medium') return { width: '20px', height: '20px' };

    return { width: '24px', height: '24px' };
  }, [sx]);

  return <div style={styles} id={id || 'spinner'} ref={containerRef} />;
}
