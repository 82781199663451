/* eslint-disable global-require */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { APP_CONSTANTS } from 'common/strings';
import lottie from 'lottie-web';
import { useNavigate } from 'react-router-dom';

import './success.style.scss';

const SuccessDialog = ({ subtitle, route = '', button_text= '' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    lottie.destroy('success-mark');
    lottie.loadAnimation({
      name: 'success-mark',
      container: document.getElementById('success-mark'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('resources/animation/success.json'),
    });
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (route === '')
          navigate(APP_CONSTANTS.ROUTES.SIGNIN);
        else 
          // navigate(route);
          window.location.href = route;
      }}
    >
      <div className="box-section">
        <div id="success-mark" />
        <h2 className="box-title success-title">{t('pages.success')}</h2>

        <div className="succes-dialog-box">
          <div className="success-subtitle">{subtitle || ''}</div>
          <Button
            type="submit"
            kind="primary"
            sx="large"
            content={button_text !== '' ? button_text : t('button.go-to-sign-in')}
          />
        </div>
      </div>
    </form>
  );
};

export default SuccessDialog;
