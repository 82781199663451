import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';

import './delete-dialog.style.scss';

const DeleteDialog = ({ message, onClose }) => {
  const ref = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, [ref]);

  return (
    <div className="delete-dialog" ref={ref}>
      <div className="delete-message">{message}</div>
      <div className="delete-button">
        <Button
          content={t('button.delete')}
          sx="xsmall"
          kind="negative"
          onClick={() => {
            onClose(true);
          }}
        />
      </div>
    </div>
  );
};

export default DeleteDialog;
