import { useCallback, useRef, useEffect } from 'react';

import './discrete-slider.style.scss';

export default function DiscreteSlider({ selected, hideMidLabel, steps, onChange }) {
  const ref = useRef();
  const thumbRef = useRef();
  const mouseClickRef = useRef(false);

  const handleChange = useCallback(
    (pos) => {
      if (ref.current && mouseClickRef.current) {
        for (let i = 0; i < steps.length; i += 1) {
          const tickPos = (ref.current.clientWidth / (steps.length - 1)) * i - 10;
          if (tickPos >= pos - 20 && tickPos <= pos + 20) {
            onChange(steps[i]);
            break;
          }
        }
      }
    },
    [steps],
  );

  useEffect(() => {
    const handleMove = (event) => {
      if (ref.current) {
        const originX = ref.current.getBoundingClientRect().x;
        handleChange(event.clientX + 10 - originX);
      }
    };
    const handleTouch = (event) => {
      if (ref.current) {
        const originX = ref.current.getBoundingClientRect().x;
        handleChange(event.touches[0].clientX + 10 - originX);
      }
    };
    const handleUp = () => {
      mouseClickRef.current = false;
    };
    document.addEventListener('mousemove', handleMove, false);
    document.addEventListener('mouseup', handleUp);

    document.addEventListener('touchmove', handleTouch, false);
    document.addEventListener('touchend', handleUp);

    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleUp);
    };
  }, []);

  useEffect(() => {
    if (steps) {
      let index = 0;
      for (let i = 0; i < steps.length; i += 1) {
        if (steps[i] === selected) {
          index = i;
          break;
        }
      }
      handleSelection(index);
    }
  }, [selected]);

  const handleSelection = useCallback(
    (idx) => {
      if (ref.current) {
        thumbRef.current.style.setProperty(
          'left',
          `${(ref.current.clientWidth / (steps.length - 1)) * idx - 10}px`,
        );
        onChange(steps[idx]);
      }
    },
    [steps, thumbRef.current],
  );

  return (
    <div className="discrete-slider-wrapper">
      <div className="start-end-tick-label">{steps[0]}</div>
      <div className="discrete-slider-container" ref={ref}>
        <div className="discrete-slider">
          <div
            style={{ width: '100%', height: '2px', backgroundColor: 'var(--slate-300)' }}
          />
          <div
            className="range-thumb-wrapper"
            ref={thumbRef}
            role="none"
            onMouseDown={(e) => {
              mouseClickRef.current = true;
            }}
            onTouchStart={(e) => {
              mouseClickRef.current = true;
            }}
          >
            <div className="range-thumb" />
          </div>
          {steps.map((st, index) => (
            <span
              key={`tick-${index}`}
              className="discrete-tick"
              style={{
                left: `calc(100% / ${steps.length - 1} * ${index})`,
              }}
              role="none"
              onClick={(e) => {
                e.stopPropagation();
                onChange(st);
              }}
            >
              <div
                style={{
                  width: '2px',
                  height: '8px',
                  backgroundColor: 'var(--slate-300)',
                }}
              />
            </span>
          ))}
        </div>
        <div className="tick-labels">
          {steps.map((st, index) => {
            const matched = selected === st;
            if (hideMidLabel && !matched) {
              return null;
            }

            let shift = 10;
            if (index === steps.length - 1) {
              shift = 8 * `${steps[index]}x`.length;
            } else if (index === 0) {
              shift = 0;
            }

            return (
              <div
                className="tick-label"
                key={`tick-label-${index}`}
                style={{
                  left: `calc(100% / ${steps.length - 1} * ${index} - ${shift}px)`,
                  color: !matched ? 'var(--gray-400)' : 'var(--primary-500)',
                  fontWeight: !matched ? 400 : 500,
                }}
              >
                {`${st}x`}
              </div>
            );
          })}
        </div>
      </div>
      <div className="start-end-tick-label">{steps[steps.length - 1]}</div>
    </div>
  );
}
