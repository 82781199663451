import { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectList } from 'components';
import { CollapseIcon, ExpandIcon, SearchIcon } from 'resources/icons';

import './dropdown.style.scss';

export default function DropDown({
  placeholder,
  selected,
  items,
  disabled,
  onSelected,
  sx,
  purchase,
  searchable,
}) {
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState('');

  const sizeClass = useMemo(() => {
    if (sx === 'medium') {
      return 'dropdown-medium';
    }
    if (sx === 'small') {
      return 'dropdown-small';
    }
    return '';
  }, [sx]);

  return (
    <div
      className={`dropdown-container ${sizeClass} ${
        disabled ? 'container-disabled' : ''
      } ${purchase ? 'purchase-container' : ''} ${open ? 'focus-within' : ''}`}
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          setOpen(!open);
        }
      }}
    >
      {searchable ? (
        <div className="dropdown-input-wrapper">
          <input
            className="dropdown-search-input"
            type="text"
            placeholder={open && selected?.text ? selected.text : placeholder}
            value={open ? keyword : selected?.text || ''}
            onClick={(e) => {
              if (open) {
                e.stopPropagation();
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Escape') {
                setOpen(false);
              }
            }}
            onChange={(e) => {
              e.stopPropagation();
              setKeyword(e.target.value);
            }}
          />
          {open && <SearchIcon className="dropdown-search-icon" />}
        </div>
      ) : (
        <div
          className={`dropdown-placeholder${selected ? '-selected' : ''} ${
            purchase ? 'purchase' : ''
          }`}
        >
          {selected ? selected.text : placeholder}
        </div>
      )}
      {open ? (
        !searchable && <CollapseIcon className="dropdown-icon" />
      ) : (
        <ExpandIcon className="dropdown-icon" />
      )}

      {open && (
        <SelectList
          keyword={searchable ? keyword : null}
          value={selected}
          items={items}
          onSelected={(obj) => {
            if (obj) {
              onSelected(obj);
            }
            if (searchable) {
              setKeyword('');
            }
            setOpen(false);
          }}
        />
      )}
    </div>
  );
}

DropDown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }),
  ).isRequired,
  onSelected: PropTypes.func.isRequired,
  purchase: PropTypes.bool,
};
