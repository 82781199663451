export const v1 = {
  account: {
    me: '/api/accounts/me',
    signin: '/api/accounts/sign_in',
    signout: '/api/signout',
    refresh: '/api/accounts/refresh_token',
    preferences: '/api/accounts/preferences',
    forgot_password: 'api/accounts/forgot_password',
    reset_password: 'api/accounts/reset_password',
    email: 'api/accounts/email',
    createindep: '/api/accounts/independent',
    createstud: '/api/accounts/student',
    acceptinvite: '/api/accounts/accept_invitation',
    frominvite: '/api/accounts/from_invite',
    purchases: '/api/accounts/mypurchases',
    extendaccess: '/api/accounts/extendaccess',
    lti: '/api/accounts/lti',
    link_lti: '/api/accounts/link_lti',
    sso: '/api/accounts/sso',
    send_verification_email: '/api/accounts/send_verification_email',
    send_verification_code: '/api/accounts/send_verification_code',
    get_verification_code: '/api/accounts/get_verification_code',
    confirm_email: '/api/accounts/confirm_email',
    check_email_confirmed: '/api/accounts/check_email_verified',
    google: '/api/accounts/google',
    set_connection_id: '/api/accounts/set_connection_id'

  },
  admin: {
    adminlist: '/api/Account/AdminList',
    anatomageadminlist: '/api/Account/AnatomageAdminList',
    indepaccountlist: '/api/Account/indepAccountList',
    institutionlist: '/api/Institution/SearchInstitutions',
    instructorlist: '/api/ClassInstructor/AdminInstructorList',
    adminview: () => {
      const url = `https://${window.location.hostname}`;

      return `${url}/lessonsAdmin/Default`;
    },
  },
  announcements: {
    announcements: '/api/announcements',
    announcement: (id) => `/api/announcements/${id}`,
    read: (id) => `/api/announcements/${id}/read`,
  },
  usage: {
    checkLastActivity: '/api/usage/checkLastActivity',
    updateLastActivity: '/api/usage/updateLastActivity',
    sendUsage: '/api/usage/send'
  },

  components: {
    components: '/api/ebookContent/moduleComponentList',
  },
  class: {
    base: '/api/classes',
    studentclasses: '/api/class/studentList',
    instructors: (id) => `/api/classes/${id}/instructors`,
    update_owner: (id) => `/api/classes/${id}/owner`,
    deleteinstructor: (id, instructor_account_id) =>
      `/api/classes/${id}/instructors/${instructor_account_id}`,
    deletestudent: (id, student_account_id) =>
      `/api/classes/${id}/students/${student_account_id}`,
    edit: (id) => `/api/classes/${id}`,
    deleteclass: (id) => `/api/classes/${id}`,
    join: 'api/classes/join',
    fromcode: 'api/classes/from_code',
    metrics: (id) => `/api/classes/${id}/metrics`,
    studentMetrics: (id) => `/api/classes/${id}/student_metrics`,
    overallStudentProgress: (class_id, student_id) => `/api/classes/${class_id}/student_metrics/${student_id}`,
    studentAssignments: (class_id, student_id) => `/api/classes/${class_id}/student_metrics/${student_id}/assignments`,
    name: (id) => `/api/classes/${id}/name`,
    check_institution_id: 'api/classes/check_institution_id'
  },
  contents: {
    attributions: '/api/contents/subject_attributions',
    subjects: '/api/contents/subjects',
    chapters: '/api/contents/chapters',
    sections: '/api/contents/sections',
    components: '/api/contents/components',
    search: '/api/contents/search',
    check3DonCloud: '/api/contents/check_3doncloud',
    contentview: (sectionId, componentId) => {
      let url = `https://${window.location.hostname}`;

      url = `${url}/contentsViewer/PreviewSection?id=${sectionId}`;
      if (componentId) {
        url = `${url}&cid=${componentId}`;
      }

      return url;
    },
    quizview: (quizId) => `https://${window.location.hostname}/3DonCloud?quiz_id=${quizId}`,
  },
  threedCloud: `https://${window.location.host}/3DonCloud/`,
  assignments: {
    assignments: '/api/assignments',
    assignment: (id) => `/api/assignments/${id}`,
    metrics: (id) => `/api/assignments/${id}/metrics`,
    studentMetrics: (id) => `/api/assignments/${id}/student_metrics`,
    assignmentDelete: (id, class_id) => `/api/assignments/${id}/${class_id}`,
    reset_quiz: '/api/assignments/reset_threed_quiz'
  },
  licenses: {
    list: '/api/licenses',
  },
  metrics: {
    avgcomponentrating: '/api/Metrics/AvgComponentRatings',
    avgsectionrating: '/api/Metrics/AvgSectionRatings',
    popularcomponents: '/api/Metrics/PopularComponents',
    popularsections: '/api/Metrics/PopularSections',
  },
  recently: {
    createdinstitutions: '/api/Metrics/RecentlyCreatedInstitutions',
    createdinstructors: '/api/Metrics/RecentlyCreatedInstructors',
    editedsections: '/api/Metrics/RecentlyEditedSections',
  },
  feedback: {
    contactUs: '/api/feedbacks/contact_us',
  },
  payment: {
    paymentIntent: '/api/payment/create-payment-intent',
    createbill: '/api/billing/createTransaction',
    updatebill: '/api/billing/updateTransaction',
    getreceipt: '/api/payment/get-receipt',
  },
  lti: {
    launch: '/api/lti/launch',
    assignment_response: '/api/lti/deepresponse',
    link: '/api/classes/lti_link_class',
    skip_linking: '/api/classes/set_lti_link_pref',
    check_class_linkage: '/api/classes/check_lti_link_status',
    get_linked_class: '/api/classes/get_linked_class',
    sync_grades: '/api/lti/syncgrades',
    check_skip_preference: '/api/classes/get_lti_link_pref',
    google: '/api/lti/auth/google',
    google_link: '/api/classes/google_link_class',
    google_response: '/api/lti/googledeepresponse',
    get_google_linked_class: '/api/classes/get_google_linked_class',
    google_sync_grades: '/api/lti/googlesyncgrades',
  },
  file: {
    upload: "/api/quiz",
    get_quiz_attempts: "/api/quiz/zip"
  },
  quiz: {
    attempt: "/api/quiz/quiz_attempt",
    response_summary: '/api/quiz/quiz_response_metrics'
  }
};
