import {
  useState,
  useRef,
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import moment from 'moment';

import { CollapseIcon, ExpandIcon } from 'resources/icons';

import './year-month-picker.style.scss';

const YearAndMonthSelection = ({ date, mobile, onSelected }) => {
  const [range] = useState([moment().year() - 5, moment().year() + 6]); // will display upto next 6 years

  const ref = useRef();

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.tagName !== 'path'
      ) {
        onSelected();
      }
    };

    if (!mobile) {
      document.addEventListener('mousedown', checkOutsideClick);
    }

    return () => {
      if (!mobile) document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, [ref]);

  const pickerRef = useRef([]);
  if (pickerRef.current.length === 0) {
    pickerRef.current = Array(range[1] - range[0])
      .fill()
      .map(() => createRef());
  }

  return (
    <div className="ym-picker-container" ref={ref}>
      {[...Array(range[1] - range[0])].map((_, index) => (
        <MonthExpanderItem
          ref={pickerRef.current[index]}
          key={`mei-${index}`}
          year={range[0] + index}
          month={date.year() === range[0] + index ? date.month() : -1}
          expand={range[0] + index === date.year()}
          onSelected={(res) => {
            onSelected(res);
          }}
          onExpanded={() => {
            for (let i = 0; i < pickerRef.current.length; i += 1) {
              if (i !== index) {
                pickerRef.current[i].current.collapse();
              }
            }
          }}
        />
      ))}
    </div>
  );
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const MonthExpanderItem = forwardRef(
  ({ year, month, onSelected, expand, onExpanded }, ref) => {
    const [expanded, setExpanded] = useState(expand);

    useImperativeHandle(ref, () => ({
      collapse() {
        setExpanded(false);
      },
    }));

    return (
      <div className="me-expander-item">
        <div
          className="mei-header"
          role="none"
          onClick={(e) => {
            e.stopPropagation();
            setExpanded((prev) => !prev);
            if (!expanded) {
              onExpanded();
            }
          }}
        >
          <div className="mei-year-font">{year}</div>
          <div className="mei-expand-btn">
            {expanded ? (
              <CollapseIcon className="dp-expander-icon" />
            ) : (
              <ExpandIcon className="dp-expander-icon" />
            )}
          </div>
        </div>
        {expanded && (
          <div className="mei-month-list">
            {months.map((mth, index) => (
              <div
                key={`${mth}-index`}
                className="mei-month-item"
                role="none"
                onClick={(e) => {
                  e.stopPropagation();
                  const picked = moment()
                    .utc()
                    .set('year', year)
                    .set('month', index)
                    .set('date', 1);
                  onSelected(picked.utc());
                }}
              >
                <div
                  className={`mei-month-pointer ${
                    index === month ? 'mei-month-pick' : ''
                  }`}
                >
                  {mth}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  },
);

export default YearAndMonthSelection;
