import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import moment from 'moment';
import { usePagination } from 'utilities/hooks';
import { useTranslation } from 'react-i18next';
import { apiClient } from 'common/api-client';
import { dateToISOString } from 'utilities/timestring';
import { v1 } from 'common/apis';
import AssignmentContext from './assignment-context';

const SORT_OPTION = [
  {
    order_by: 'due_date',
    order: 'asc',
  },
  {
    order_by: 'due_date',
    order: 'desc',
  },
  {
    order_by: 'start_date',
    order: 'asc',
  },
  {
    order_by: 'start_date',
    order: 'desc',
  },
  {
    order_by: 'completion_ratio',
    order: 'desc',
  },
  {
    order_by: 'completion_ratio',
    order: 'asc',
  },
  {
    order_by: 'assignment_name',
    order: 'asc',
  },
  {
    order_by: 'assignment_name',
    order: 'desc',
  },
];

export default function AssignmentProvider({ children }) {
  const { t } = useTranslation();
  const [assignments, setAssignments] = useState([]);
  const [pastDue, setPastDue] = useState([]); // start and end date
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [sorted, setSorted] = useState();
  const [sortOption, setSortOption] = useState({ id: 0, text: '' });
  const [enoughCount, setEnoughCount] = useState(false); // this is for the time spent
  const [classes, setClasses] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [selectedClass, setSelectedClass] = useState({
    id: 'all',
    text: t('field.all-classes'),
  });

  const prevClasses = useRef({
    id: 'all',
    text: t('field.all-classes'),
  });

  const { loading, setLoading, paginationRef, setHasMore, page, setPage } =
    usePagination();
  const {
    loading: pastDueLoading,
    setLoading: setPastDueLoading,
    paginationRef: pastDuePaginationRef,
    setHasMore: setPastDueHasMore,
    setPage: setPastDuePage,
    page: pastDuePage,
  } = usePagination();

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    if (selectedClass.id !== prevClasses.current.id) {
      prevClasses.current = selectedClass;
    }
  }, [selectedClass]);

  useEffect(() => {
    if (page === 1) {
      refreshAssignment();
    } else {
      fetchAssignments();
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      refreshAssignment(null, true);
    } else {
      fetchAssignments(true);
    }
  }, [pastDuePage]);

  const refresh = useCallback(
    (props) => {
      refreshAssignment(props);
      refreshAssignment(props, true);
    },
    [page, pastDuePage, selectedClass, sortOption],
  );

  const refreshAssignment = useCallback(
    (option, past) => {
      if (past) {
        if (pastDuePage === 1) {
          fetchAssignments(option, true);
        } else {
          setPastDuePage(1);
        }
      } else if (page === 1) {
        fetchAssignments(option);
      } else {
        setPage(1);
      }
    },
    [page, pastDuePage, selectedClass],
  );

  const fetchClasses = useCallback(async () => {
    try {
      const response = await apiClient.get(v1.class.base);

      const { data } = response;
      setClasses(data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchAssignments = useCallback(
    async (option, past) => {
      try {
        if (past) {
          setPastDueLoading(true);
        } else {
          setLoading(true);
        }

        const sort = option?.sort?.id || sortOption.id;

        if (selectedClass) {
          const params = {
            page_no: page,
            include_user_metric: true,
            ...SORT_OPTION[sort],
          };

          const classId = option?.class?.id || selectedClass.id;
          if (classId !== 'all') {
            params.class_ids = classId;
          }
          if (past) {
            params.due_date_to = dateToISOString(moment().subtract(1, 'day'));
          } else {
            params.due_date_from = dateToISOString(moment());
          }
          const response = await apiClient.get(v1.assignments.assignments, {
            params,
          });
          const { data, status } = response;
          const { pagination } = data;
          if (past) {
            if (page === 1) {
              setPastDue(data.data);
            } else {
              setPastDue((prev) => [...prev, ...data.data]);
            }
            setPastDueHasMore(!!pagination?.next_page);
          } else {
            if (page === 1) {
              setAssignments(data.data);
            } else {
              setAssignments((prev) => [...prev, ...data.data]);
            }
            setHasMore(!!pagination?.next_page);
          }

          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
    [page, selectedClass, sortOption],
  );

  const value = useMemo(
    () => ({
      sortOption,
      setSortOption,
      classes,
      setClasses,
      loading,
      setLoading,
      selectedClass,
      setSelectedClass,
      assignments,
      setAssignments,
      pastDue,
      setPastDue,
      assignedClasses,
      setAssignedClasses,
      sorted,
      setSorted,
      fetchAssignments,
      fetchClasses,
      refresh,
      refreshAssignment,
      paginationRef,
      pastDuePaginationRef,
      enoughCount,
      setEnoughCount,
    }),
    [
      assignments,
      pastDue,
      assignedClasses,
      sorted,
      sortOption,
      classes,
      loading,
      selectedClass,
      pastDueLoading,
      paginationRef,
      pastDuePaginationRef,
      enoughCount,
    ],
  );

  return (
    <AssignmentContext.Provider value={value}>{children}</AssignmentContext.Provider>
  );
}
