import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import './mobile-dialog.style.scss';

export default function MobileDialog({ children, scrollRef, onClose }) {
  const ref = useRef();

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (onClose) onClose(false);
      }
    };

    if (onClose) document.addEventListener('mousedown', checkOutsideClick);

    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();
      let newHeight = height;
      const portion = newHeight / window.innerHeight;
      if (portion > 0.6) {
        newHeight = '60%';
      } else if (portion < 0.4) {
        newHeight = '40%';
      } else {
        newHeight = `${newHeight}px`;
      }
      ref.current.style.setProperty('--position', `calc(100% - ${newHeight})`);
      ref.current.style.setProperty('height', `calc(100% - var(--position))`);
    }

    document.documentElement.style.setProperty('overscroll-behavior', 'none');

    return () => {
      if (onClose) document.removeEventListener('mousedown', checkOutsideClick);
      document.documentElement.style.setProperty('overscroll-behavior', 'unset');
    };
  }, [ref]);

  return createPortal(
    <div className="mobile-dialog-wrapper">
      <div className="mobile-dialog-container" ref={ref}>
        <div
          className="mobile-dialog-head"
          onTouchMove={(e) => {
            if (ref.current) {
              const { height } = ref.current.getBoundingClientRect();

              if (height < 100) {
                onClose(false);
              } else {
                ref.current.style.setProperty('--position', `${e.touches[0].clientY}px`);
              }
            }
          }}
        >
          <div className="mobile-dialog-control" />
        </div>
        <div className="mobile-dialog-body" ref={scrollRef}>
          {children}
        </div>
      </div>
    </div>,
    document.querySelector('#root'),
  );
}
