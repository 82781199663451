import { useRef } from 'react';

import './tooltip.style.scss';

export default function Tooltip({ children, right, text }) {
  const tooltipRef = useRef();
  const containerRef = useRef();
  const pointRef = useRef();

  return (
    <div
      className="tooltip-container"
      ref={containerRef}
      onMouseEnter={() => {
        if (tooltipRef.current && containerRef.current) {
          const bound = tooltipRef.current.getBoundingClientRect();
          if (bound.left < 0 || right) {
            containerRef.current.style.setProperty('--right', 'unset');
            containerRef.current.style.setProperty('--left', `${bound.width / 4}px`);
            pointRef.current.style.setProperty('right', 'unset');
            pointRef.current.style.setProperty('left', '5px');
          }

          if (bound.bottom >= window.innerHeight) {
            containerRef.current.style.setProperty('--top', 'unset');
            containerRef.current.style.setProperty('--bottom', `24px`);
            pointRef.current.style.setProperty('transform', 'rotateX(180deg)');
          }
        }
      }}
    >
      {children}
      <div className="bubble-point" ref={pointRef} />
      <div className="tooltip-bubble" ref={tooltipRef}>
        {text}
      </div>
    </div>
  );
}
