import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './progress.style.scss';

export default function ProgressRing({ value, max, type='percent', shape=null }) {
  const ref = useRef();

  useEffect(() => {
    let deg = Math.min(1, value / max) * 360;
    if (deg === 360) {
      deg = 361;
    }
    ref.current.style.setProperty('--progress', `${deg}deg`);
  }, [value]);

  return (
    <div className="progress-ring-container">
      <div className="progress-ring-curve" ref={ref} />
      <div className="progress-ring-label">{ type === 'percent' ? ((value / max) * 100).toFixed(0) : max.toFixed(0) }{shape === 'percent' && '%'}</div>
    </div>
  );
}

ProgressRing.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};
