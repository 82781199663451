import { useLayoutEffect, useRef, useEffect, useCallback, useMemo } from 'react';
import { MobileDialog } from 'components';
import { CheckMarkIcon } from 'resources/icons';
import { isMobileDevice } from 'utilities/validation';

import './select-list.style.scss';

export default function SelectList({ value, items, keyword, right, onSelected, leftOnMinimize = false }) {
  const ref = useRef();

  const keywordRef = useRef('');

  useEffect(() => {
    if (typeof keyword !== 'string') {
      window.addEventListener('keydown', handleSearch);
    }

    return () => {
      if (typeof keyword !== 'string') {
        window.removeEventListener('keydown', handleSearch);
      }
    };
  }, [keyword]);

  // useEffect(() => {
  //   if (keyword) {
  //     const res = onSearch(keyword);
  //     if (!res) {
  //       setKeyword('');
  //     } else {
  //       setFocused(res);
  //     }
  //   }
  // }, [keyword]);

  const handleSearch = useCallback((ev) => {
    if (ev.key === 'Escape') {
      onSelected();
      keywordRef.current = '';
    } else if (!/[a-z]/i.test(String.fromCharCode(ev.which)) === false) {
      keywordRef.current = `${keywordRef.current}${ev.key}`;
      scrollTo(keywordRef.current, true);
    } else if (ev.key === 'Backspace') {
      keywordRef.current = keywordRef.current.substring(0, keywordRef.current.length - 1);
      scrollTo(keywordRef.current, true);
    }
  }, []);

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onSelected();
      }
    };
    document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, [ref]);

  const scrollToCurrent = useCallback(() => {
    scrollTo(value?.text);
  }, [value]);

  const scrollTo = (text, ignoreCase) => {
    const { children } = ref.current;
    for (let i = 0; i < children.length; i += 1) {
      const matchText = ignoreCase
        ? children[i].textContent.toLowerCase()
        : children[i].textContent;

      let isMatched = matchText === text;
      if (ignoreCase) {
        isMatched = matchText.startsWith(text);
      }
      if (isMatched) {
        if (typeof children[i].scrollIntoView === 'function') {
          children[i].scrollIntoView({
            // behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }

        children[i].focus();
        break;
      }
    }
  };

  useLayoutEffect(() => {
    if (ref.current) {
      scrollToCurrent();
    }
    if (right && ref.current) {
      ref.current.style.setProperty('left', 'unset');
      ref.current.style.setProperty('right', '0px');
    }

    if (leftOnMinimize && ref.current) {
      ref.current.classList.add('left-on-minimize');
    }
  }, [right, leftOnMinimize, ref]);

  const filteredItems = useMemo(() => {
    if (typeof keyword === 'string') {
      return items.filter((it) =>
        it.text.toLowerCase().startsWith(keyword.toLowerCase()),
      );
    }

    return items;
  }, [keyword]);

  if (isMobileDevice()) {
    return (
      <MobileDialog
        scrollRef={ref}
        onClose={() => {
          onSelected();
        }}
      >
        <ListComponent list={filteredItems} value={value} onSelected={onSelected} />
      </MobileDialog>
    );
  }

  return (
    <div className="selectlist-items" ref={ref}>
      <ListComponent list={filteredItems} value={value} onSelected={onSelected} />
    </div>
  );
}

const ListComponent = ({ list, value, onSelected }) =>
  list.length > 0 ? (
    list.map((item, index) => (
      <div
        key={`selectlist-item${index}`}
        className={`selectlist-item ${value?.id === item.id ? 'selected-item' : ''}`}
        role="none"
        onClick={(e) => {
          e.stopPropagation();
          if (item.func) {
            item.func();
          }

          onSelected(item);
        }}
      >
        <div className="icon-wrapper">{value?.id === item.id && <CheckMarkIcon />}</div>
        <div className="selectlist-item-text">{item.text}</div>
      </div>
    ))
  ) : (
    <div
      className="selectlist-item-not-found"
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        onSelected();
      }}
    >
      Not found
    </div>
  );
