export const FourDigit = (num) => {
  const regx = /^[0-9]{0,4}$/;
  return regx.test(num);
};

export const isNumber = (num) => {
  const regx = /^[0-9]*$/;
  return regx.test(num);
};

export const mmyyFormat = (num) => {
  const regx = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
  return regx.test(num);
};
