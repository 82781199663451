export const APP_CONSTANTS = {
  ROUTES: {
    SIGNIN: '/signin',
    FORGOTPASSWORD: '/forgotpassword',
    RESETPASSWORD: '/reset_password',
    JOIN_CLASS: '/join',
    SIGNUP: '/signup',
    INST_SIGNUP: '/instructorSignUp',
    STUD_SIGNUP: '/studentSignUp',
    HOME: '/home',
    CONTACTUS: '/contact-us',
    ABOUTUS: '/about-us',
    ACCOUNT: '/account',
    ANNOUNCEMENT: {
      BASE: '/announcement',
      CREATE: '/announcement/create',
      EDIT: '/announcement/edit',
    },
    PREFERENCE: '/preference',
    CONTENTS: {
      BASE: '/contents',
      DETAIL: '/contents/detail',
      SEARCH: '/contents/searchResult',
      ATTRIBUTIONS: '/contents/attributions'
    },
    CALENDAR: '/calendar',
    FEEDBACK: '/feedback',
    HELP: '/help',
    ASSIGNMENT: {
      BASE: '/assignments',
      CREATE: '/assignments/create',
      EDIT: '/assignments/edit',
      DETAIL: '/assignments/details',
    },
    ASSIGN_LIST: '/AssignmentList',
    CLASSES: {
      BASE: '/classes',
      CREATE: '/classes/create',
      EDIT: '/classes/edit',
      DETAIL: '/classes/details',
      ENROLL: '/classes/enroll',
    },
    ADMIN: {
      ADMIN_LIST: {
        BASE: '/admins',
      },
      INSTITUTION_LIST: {
        BASE: '/institutions',
        CREATE: '/institutions/create',
        EDIT: '/institutions/edit',
      },
      INSTR_LIST: {
        BASE: '/instructors',
      },
      INDEP_ACCOUNT_LIST: {
        BASE: '/indepaccounts',
        ANATOMAGE_ADMINS: 'indepaccountlist/anatomage',
        EDIT: '/indepaccountlist/edit',
      },
      RATINGS: '/Ratings',
    },
    LTI : {
        ERROR: '/lti-error'
    },
    THREED_ON_CLOUD: '/3DonCloud',
    RENEWAL: '/renewal',
    MYPURCHASES: '/mypurchases',
    COURSELINKING: '/courselinking',
    ASSIGNMENTLINKING: '/assignmentlinking',
    VERIFYEMAIL: '/verify_email'
  },
};

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const fulldays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
