import { useContext , React } from 'react';
import DOMPurify from 'dompurify';
import { subjectImageUrl } from 'utilities/mapper';
import { Chip, Skeleton } from 'components';
import { UserContext } from 'contexts';
import { v1 } from 'common/apis';

import './subject-card.style.scss';

const SubjectCard = ({ loading, data }) => {
  const { isLTILaunch } = useContext(UserContext);

  if (loading)
    return (
      <div className="rcv-card-container">
        <Skeleton width="122px" height="154px" />
        <div className="rcv-card-info-wrapper">
          <Skeleton width="130px" height="20px" />
          <div className="rcv-card-info">
            <div className="rcv-card-chapter">
              <Skeleton width="150px" height="18px" />
            </div>
            <div className="rcv-card-section">
              <Skeleton width="120px" height="18px" />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className="rcv-card-container"
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        window.open(v1.contents.contentview(data.section_id),  isLTILaunch() ? '_self' : '_blank');
      }}
    >
      <div
        className="rcv-card-image"
        style={{ background: `url(${subjectImageUrl(data.subject_id)})` }}
      >
        <div className="rcv-subject-name">{data.subject_name}</div>
      </div>
      <div className="rcv-card-info-wrapper">
        <Chip text={data.subject_name} type="gray" fontSize="12px" />
        <div className="rcv-card-info">
          <div className="rcv-card-chapter">{data.chapter_name}</div>
          <div
            className="rcv-card-section"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.section_name) }}
          />
        </div>
      </div>
    </div>
  );
};

export default SubjectCard;
