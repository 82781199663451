import { useContext, useMemo, useState } from 'react';
import { UserContext, BarsContext } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS } from 'common/strings';
import { AppLogo, HamburgerMenu, BellIcon } from 'resources/icons';
import { SearchBar, UserMenu } from 'components';
import { isMobileDevice } from 'utilities/validation';
import queryString from 'query-string';


import { useTranslation } from 'react-i18next';

import './appbar.style.scss';

export default function AppBar() {
  const { t } = useTranslation();
  const { isAuthenticated, isDeepLaunch } = useContext(UserContext);
  const { setCollapsed } = useContext(BarsContext);
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);

  const authenticated = isAuthenticated();

  // Hide top app-bar in deep link content selection
  if (isDeepLaunch() || query.itemType) {
    return null;
  }

  const buttonsDisabled = () => {
    if (window.location.pathname === '/lti-error') return true;
    return false;
  }

  return (
    <div className="appbar-container decorated-border">
      {authenticated && (
        <HamburgerMenu
          className="hamburger-menu"
          role="none"
          onClick={() => {
            setCollapsed((prev) => !prev);
          }}
        />
      )}
      <div className="ebook-title-wrapper">
        <div
          className={!buttonsDisabled() ? "ebook-logo-wrapper" : ""}
          role="none"
          onClick={() => {
            if (!buttonsDisabled()) navigate(APP_CONSTANTS.ROUTES.HOME);
          }}
        >
          <AppLogo />
        </div>
        <span id="temp-ebook-title"> (previously eBook) </span>
      </div>

      {!authenticated && !isMobileDevice() && !buttonsDisabled ? (
        <div className="appbar-navs">
          {/* <a className="appbar-nav" href={APP_CONSTANTS.ROUTES.CONTACTUS}>
            {t('menu.contact-us')}
          </a> */}
          <a className="appbar-nav" href={APP_CONSTANTS.ROUTES.ABOUTUS}>
            {t('menu.about-us')}
          </a>
          <a className="appbar-nav" href={APP_CONSTANTS.ROUTES.HELP}>
            {t('menu.help')}
          </a>
          <a className="appbar-nav" href={APP_CONSTANTS.ROUTES.SIGNIN}>
            {t('auth.signin')}
          </a>
        </div>
      ) : (
        <div className="app-right-items">
          {/* <SearchBar />
          <div className="notification-bell">
            <BellIcon />
          </div> */}
          <UserMenu />
        </div>
      )}
    </div>
  );
}
