import { useState, useEffect, useRef } from 'react';

import './progress.style.scss';

export default function ProgressBar({
  value,
  secondValue,
  max,
  small,
  medium,
  color,
  disabled,
}) {
  const ref = useRef();

  useEffect(() => {
    if (typeof value !== 'undefined') {
      ref.current.style.setProperty(
        '--first-progress',
        `${Math.min(max === 0 ? max : (value / max) * 100, 100)}%`,
      );

      if (max === 0 || value / max < 1) {
        ref.current.style.setProperty('--first-border-radius', '4px 0px 0px 4px');
      } else {
        ref.current.style.setProperty('--first-border-radius', '4px');
      }
    }
  }, [value, max]);

  useEffect(() => {
    if (color === 'green') {
      if (disabled) {
        ref.current.style.setProperty('--first-color', 'var(--teal-200)');
        ref.current.style.setProperty('--second-color', 'var(--green-300)');
      } else {
        ref.current.style.setProperty('--first-color', 'var(--teal-700)');
        ref.current.style.setProperty('--second-color', 'var(--green-400)');
      }
      ref.current.style.setProperty('--background', 'var(--green-300)');
    } else if (color === 'blue') {
      if (disabled) {
        ref.current.style.setProperty('--first-color', 'var(--primary-350)');
        ref.current.style.setProperty('--second-color', 'var(--green-300)');
        ref.current.style.setProperty('--background', 'var(--green-200)');
      } else {
        ref.current.style.setProperty('--first-color', 'var(--blue-600)');
        ref.current.style.setProperty('--second-color', 'var(--green-400)');
        ref.current.style.setProperty('--background', 'var(--green-400)');
      }
    } else if (color === 'purple') {
      ref.current.style.setProperty('--first-color', 'var(--purple-500)');
      ref.current.style.setProperty('--second-color', 'var(--slate-200)');
      ref.current.style.setProperty('--background', 'var(--slate-200)');
    }
  }, [color, disabled]);

  useEffect(() => {
    if (typeof secondValue !== 'undefined') {
      ref.current.style.setProperty(
        '--second-progress',
        `${Math.min(max === 0 ? 0 : (secondValue / max) * 100, 100)}%`,
      );
      if (max === 0 || secondValue / max < 1) {
        ref.current.style.setProperty('--second-border-radius', '4px 0px 0px 4px');
      } else {
        ref.current.style.setProperty('--second-border-radius', '4px');
      }
    }
  }, [secondValue]);

  return (
    <div
      className={`progress-bar-container ${small ? 'small' : ''} ${
        medium ? 'medium' : ''
      }`}
      ref={ref}
    >
      {typeof secondValue !== 'undefined' && <div className="second-progress" />}
      <div className="first-progress" />
    </div>
  );
}
