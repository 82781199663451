import { fulldays } from 'common/strings';

export const pathWithQueries = (path, queries) => {
  const contig = Object.keys(queries).reduce((prev, next) => {
    let newStr = prev;
    if (!newStr) {
      newStr = '?';
    } else {
      newStr = `${newStr}&`;
    }

    return `${newStr}${next}=${queries[next]}`;
  }, '');
  return `${path}${contig}`;
};

export const phoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match && match[0][0] !== '0') {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  if (cleaned.length === 10) {
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if (cleaned.length === 11) {
    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  if (cleaned.length > 3 && cleaned.length < 8) {
    return cleaned.replace(/(\d{3})(\d{1})/, '$1-$2');
  }

  if (cleaned.length > 9) {
    return cleaned.replace(/(\d{3})(\d{4})/, '$1-$2');
  }

  return str;
};

export const initial = (name) => {
  if (name.includes('@')) return `${name.split('@')[0].substring(0, 2)}`;
  // eslint-disable-next-line prefer-regex-literals
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  const initials = [...name.matchAll(rgx)] || [];

  if (initials.length === 0 || !initials[0] || !initials[1]) {
    return name.replace(' ', '').substring(0, 1);
  }
  return initials.shift()?.[1] || '';
  // return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
};

export const avatarColor = (name) => {
  const colors = ['gray', '100', '200'];

  let codes = 0;
  const initialChar = initial(name);

  for (let i = 0; i < initialChar.length; i += 1) {
    codes += initialChar.charCodeAt(i);
  }

  return colors[codes % colors.length];
};

export const classColor = (name) => {
  const colors = ['purple', 'blue', 'green', 'yellow', 'orange', 'red'];
  const levels = [400, 500, 600, 300];

  let codes = 0;

  for (let i = 0; i < name.length; i += 1) {
    codes += name.charCodeAt(i);
  }

  const color = colors[codes % colors.length];
  const level = levels[codes % levels.length];

  return `${color}-${level}`;
};

const toHoursAndMinutes = (seconds) => {
  const totalMinutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { h: hours, m: minutes, s: secs };
};

export const timeStringFormatter = (seconds, noDefaultStringAllow) => {
  const converted = toHoursAndMinutes(seconds);

  let timeString = '';
  let overHour = false;
  if (converted.h > 0) {
    overHour = true;
    timeString = `${converted.h}${converted.m > 0 ? 'hr' : ' hr'}`;
  }

  if (converted.m > 0) {
    timeString = `${timeString}${timeString.length > 0 ? ' ' : ''}${converted.m}${
      converted.h > 0 ? 'min' : ' min'
    }`;
  }

  if (!overHour) {
    if (converted.s > 0) {
      timeString = `${timeString}${timeString.length > 0 ? ' ' : ''}${converted.s} sec`;
    }
  }

  if (!noDefaultStringAllow && timeString.length === 0) {
    timeString = '0 min';
  }

  return timeString;
};

const toHours = (min) => {
  const hours = Math.floor(min / 60);
  const minutes = min % 60;

  return { h: hours, m: minutes };
};

export const timeMinStringFormatter = (minutes, noDefaultStringAllow) => {
  const converted = toHours(minutes);

  let timeString = '';
  if (converted.h > 0) {
    timeString = `${converted.h}${converted.m > 0 ? 'hr' : ' hr'}`;
  }

  if (converted.m > 0) {
    timeString = `${timeString}${timeString.length > 0 ? ' ' : ''}${converted.m}${
      converted.h > 0 ? 'min' : ' min'
    }`;
  }

  if (!noDefaultStringAllow && timeString.length === 0) {
    timeString = '0 min';
  }

  return timeString;
};

export const highlightText = (keyword, text) => {
  if (!text) return '';

  const regx = new RegExp(keyword, 'gi');
  return text.replace(regx, `<span id="highlight-keyword">$&</span>`);
};

export const daysOfWeek = (days) =>
  days.reduce((prev, next, index) => {
    if (next > 0) {
      if (prev) {
        return `${prev} ${fulldays[index].substring(0, 3)}`;
      }
    }

    return prev;
  }, '');
