import { useState, useRef, useCallback, useMemo, useContext, useEffect } from 'react';
import { UserContext } from 'contexts';

const usePagination = () => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { updateActiveState } = useContext(UserContext);

  const observer = useRef();
  const cancelRef = useRef();

  useEffect(() => {
    updateActiveState(true);
  }, [loading]);

  const paginationRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      if (!hasMore) return;

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const value = useMemo(
    () => ({
      loading,
      setLoading,
      hasMore,
      setHasMore,
      page,
      setPage,
      paginationRef,
      cancelRef,
    }),
    [loading, hasMore, page],
  );

  return value;
};

export default usePagination;
