import Cookies from 'js-cookie';

export const setAccessToken = (accessToken, expiresIn, lax=true) => {
  // selenium test
  if (window.location.origin.includes('localhost')) {
    Cookies.set('jwt', accessToken);
  } else {
    // must be 'None' to work in LMS embedded view
    Cookies.set('jwt', accessToken, { secure: true, sameSite: lax ? 'Lax' : 'None'});
  }

  if (!lax) {
    document.cookie = `jwt=${accessToken}; Secure; SameSite=None; Partitioned; path=/; max-age=${expiresIn}`;
  }
};

export const accessToken = () => Cookies.get('jwt');

export const removeAccessToken = () => {
  document.cookie = `jwt=${accessToken}; Secure; SameSite=None; Partitioned; path=/; max-age=0`;
  Cookies.remove('jwt');
  Cookies.remove('jwt', { secure: true, sameSite:'None' });
};

export const setCookieAccept = (accept, expiresIn) => {
  // selenium test
  localStorage.setItem('ca', accept);
};

export const cookieAccept = () => localStorage.getItem('ca');

export const removeCookieAccept = () => {
  localStorage.removeItem('ca');
};
export const setRefreshToken = (accessToken, expiresIn, lax=true) => {
  Cookies.set('refresh', accessToken, {
    secure: true,
    sameSite: 'None',
    expires: new Date(expiresIn)
    });

  if (!lax) {
    document.cookie = `refresh=${accessToken}; Secure; SameSite=None; Partitioned; path=/; max-age=${expiresIn}`;
  }
};

export const refreshToken = () => Cookies.get('refresh');

export const removeRefreshToken = () => {
  document.cookie = `refresh=${refreshToken}; Secure; SameSite=None; Partitioned; path=/; max-age=0`;
  Cookies.remove('refresh');
  Cookies.remove('refresh', { secure: true, sameSite:'None' });
};
