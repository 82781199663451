import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 */
export const msalConfig = {
    auth: {
        // https://anatomagelessons.b2clogin.com/anatomagelessons.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_lessons_sso&client_id=81b36803-c679-4844-b737-b224cdc6408f&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&scope=openid&response_type=id_token&prompt=login
        clientId: "32f6b34a-9912-49a0-9063-daba73f0a651",
        authority: "https://anatomagelessons.b2clogin.com/anatomagelessons.onmicrosoft.com/B2C_1_lessons_sso",
        knownAuthorities: ["anatomagelessons.b2clogin.com"], // array of domains that are known to be trusted
        redirectUri: `https://www.anatomagelessons.com/`,
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
        responseType: 'id_token',
    },
    cache: {
        cacheLocation: "sessionStorage", // "sessionStorage" or"localStorage"
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                    default:
                        break;
                }
            },
        },
        allowNativeBroker: false,
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    // scopes: ["Files.Read"],
    scopes: ["profile"],
    // scopes: ["User.Read"]
};