import { useContext, useMemo, useState } from 'react';
import { UserContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Avatar, MenuList } from 'components';
import { v1 } from 'common/apis';
import { apiClient } from 'common/api-client';
import { APP_CONSTANTS } from 'common/strings';
import {
  CollapseIcon,
  ExpandIcon,
  UserIcon,
  LogoutIcon,
  AboutUsIcon,
  HelpIcon,
  SettingsIcon,
  ContactIcon,
  HamburgerMenu,
  PurchaseIcon
} from 'resources/icons';
import { isMobileDevice } from 'utilities/validation';

import './user-menu.style.scss';

export default function UserMenu() {
  const { user, isInstructor, isIndepAccount, signOut, isAuthenticated } = useContext(UserContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const items = useMemo(() => {
    let menus = [];

    if (isAuthenticated()) {
      menus = [
        {
          icon: <UserIcon />,
          name: t('menu.my-account'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.ACCOUNT);
          },
        },
        {
          icon: <SettingsIcon />,
          name: t('menu.settings'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.PREFERENCE);
          },
        },
        {
          icon: <ContactIcon />,
          name: t('menu.contact-us'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.CONTACTUS);
          },
        },
        {
          icon: <HelpIcon />,
          name: t('menu.help'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.HELP);
          },
        },
        {
          icon: <AboutUsIcon />,
          name: t('menu.about-us'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.ABOUTUS);
          },
        }];

        if (isIndepAccount()) {
          menus.push( {
            icon: <PurchaseIcon />,
            name: t('menu.my-purchases'),
            func: () => {
              navigate(APP_CONSTANTS.ROUTES.MYPURCHASES);
            }
          });
        }

        menus.push({
          icon: <LogoutIcon />,
          name: t('menu.sign-out'),
          func: async () => {
            await apiClient.post(
              v1.usage.sendUsage,
              {
                textBookComponentId: -1,
                templateId: -1,
                actionType: 10
            });
            signOut(true);
            navigate(APP_CONSTANTS.ROUTES.SIGNIN);
          },
        });
    } else {
      menus = [
        {
          icon: <HelpIcon />,
          name: t('menu.help'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.HELP);
          },
        },
        {
          icon: <AboutUsIcon />,
          name: t('menu.about-us'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.ABOUTUS);
          },
        },
        {
          icon: <UserIcon />,
          name: t('auth.signin'),
          func: () => {
            navigate(APP_CONSTANTS.ROUTES.SIGNIN);
          },
        },
      ];
    }

    return menus;
  }, [user]);

  return (
    <div
      className="user-menu-container"
      role="none"
      onClick={() => {
        setOpen((prev) => !prev);
      }}
    >
      {user && (
        <div className="user-menu-wrapper">
          <Avatar
            name={`${user.first_name} ${user.last_name}`}
            color={isInstructor() ? 'blue' : 'yellow'}
            width={32}
            height={32}
          />
          <div className="user-menu-info">
            <div className="user-menu-name">{`${user.first_name} ${user.last_name}`}</div>
            <div className="user-menu-role">{user.account_type_name}</div>
          </div>
          {open ? (
            <CollapseIcon className="um-icon" />
          ) : (
            <ExpandIcon className="um-icon" />
          )}
        </div>
      )}
      {!isAuthenticated() && isMobileDevice() && <HamburgerMenu />}
      {open && (
        <MenuList
          mobile="drawer"
          items={items}
          offset={{ x: 0, y: 60 }}
        />
      )}
    </div>
  );
}
