import { useState, useEffect, useContext } from 'react';
import { ToastContext, UserContext } from 'contexts';
import TimeOutDialog from 'components/timeout/timeout';
import { cookieAccept, setCookieAccept } from 'common/storage';
import { CookieDialog, Toast } from 'components';

export default function GlobalOps() {
  const [openCookies, setOpenCookies] = useState(false);
  const { open, toast, type, showTimeout, setShowTimeout } = useContext(ToastContext);
  const { user, checkLastActivity } = useContext(UserContext);

  useEffect(() => {
    const useCookie = cookieAccept();
    setOpenCookies(!useCookie);
  }, []);

  return (
    <>
      {open && <Toast toast={toast} type={type} />}
      {user && showTimeout && (
        <TimeOutDialog
          onClose={(res) => {
            checkLastActivity(res, true);
            setShowTimeout(false);
          }}
        />
      )}
      <div>
        {openCookies && (
          <CookieDialog
            onClose={() => {
              setOpenCookies(false);
              setCookieAccept(true, '');
            }}
          />
        )}
      </div>
    </>
  );
}
