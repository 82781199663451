import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CheckIcon } from 'resources/icons';
import './checkbox.style.scss';

const CheckBox = ({ value, color, disabled, onChecked }) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const checkboxStyle = useMemo(() => {
    const style = {};
    if (color) {
      style.borderColor = `var(--${color})`;
      if (checked) {
        style.backgroundColor = `var(--${color})`;
      }
    }

    return style;
  }, [color, checked]);

  const handleChecked = (e, chk) => {
    if (onChecked) {
      setChecked(chk);
      e.stopPropagation();
      onChecked(chk);
    }
  };
  return (
    <div
      className={`checkbox-container ${checked ? 'box-checked' : ''}`}
      role="none"
      style={checkboxStyle}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          handleChecked(e, !checked);
        }
      }}
    >
      {checked && <CheckIcon />}
    </div>
  );
};

export default CheckBox;

CheckBox.propTypes = {
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChecked: PropTypes.func.isRequired,
};
