import { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { XIcon } from 'resources/icons';

import './switch.style.scss';

const Switch = ({ value, offLabel, onLabel, fontSize, onChange, disabled }) => {
  const [on, setOn] = useState(false);

  useLayoutEffect(() => {
    if (typeof value !== 'undefined') {
      setOn(value);
    }
  }, [value]);

  return (
    <div className="switch-container">
      {offLabel && (
        <div
          className={`switch-off${!on ? ' checked' : ''}`}
          style={{ fontSize: `${fontSize}px` || '14px' }}
        >
          {offLabel}
        </div>
      )}
      <SwitchBody
        disabled={disabled || false}
        checked={on}
        onChange={(res) => {
          setOn(res);
          onChange(res);
        }}
      />
      {onLabel && (
        <div
          className={`switch-on${on && !disabled ? ' checked' : ''}`}
          style={{ fontSize: `${fontSize}px` || '14px' }}
        >
          {onLabel}
        </div>
      )}
    </div>
  );
};

Switch.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  offLabel: PropTypes.string,
  onLabel: PropTypes.string,
  fontSize: PropTypes.number,
  onChange: PropTypes.func,
};

export default Switch;

const SwitchBody = ({ checked, disabled, onChange }) => (
  <div
    className={`switch-wrapper${checked ? ' switch-wrapper-on' : ''}${
      disabled ? ' switch-wrapper-disabled' : ''
    }`}
    role="none"
    onClick={() => {
      onChange(!checked);
    }}
  >
    <div className="switch-head">
      <div className="switch-disabled-x">
        <XIcon width="7px" height="7px" />
      </div>
    </div>
    <div className="switch-bar" />
  </div>
);

SwitchBody.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
