import { useState, useMemo } from 'react';
import moment from 'moment';

import ToastContext from './toast-context';

export default function ToastProvider({ children }) {
  const [toast, setMessage] = useState();
  const [type, setType] = useState('info');
  const [open, setOpen] = useState(false);
  const [showTimeout, setShowTimeout] = useState(false);

  const setToast = (msg, msgType) => {
    setMessage(msg);
    if (msgType) {
      setType(msgType);
    } else {
      setType('info');
    }

    // setOpen(true);
  };

  const value = useMemo(
    () => ({ toast, setToast, open, setOpen, type, showTimeout, setShowTimeout }),
    [toast, open, type, showTimeout],
  );

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
}
