import { useState, useEffect, useMemo } from 'react';
import { dateToISOString } from 'utilities/timestring';
import moment from 'moment';

import DatePickerContext from './datepicker-context';

export default function DatePickerProvider({ nextDay, children }) {
  const [dates, setDates] = useState([
    dateToISOString(moment()),
    dateToISOString(nextDay ? moment().add(1, 'day') : moment()),
  ]); // start and end date

  const value = useMemo(
    () => ({
      dates,
      setDates,
    }),
    [dates],
  );

  return (
    <DatePickerContext.Provider value={value}>{children}</DatePickerContext.Provider>
  );
}
