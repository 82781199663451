import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import './toggle-button.style.scss';

const ToggleButton = ({
  value,
  sx,
  startIcon,
  endIcon,
  content,
  disabled,
  onPressed,
}) => {
  const className = useMemo(() => {
    let name = 'toggle-button-container';
    if (value) {
      name = `${name} toggle-active`;
    }

    // size
    if (sx === 'medium') {
      name = `${name} toggle-size-medium`;
    } else if (sx === 'small') {
      name = `${name} toggle-size-small`;
    } else {
      name = `${name} toggle-size-large`;
    }

    if (disabled) {
      name = `${name} toggle-disabled`;
    }

    return name;
  }, [value, sx, disabled]);

  return (
    <div
      className={className}
      role="none"
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onPressed(!value);
        }
      }}
    >
      {startIcon || null}
      {content && <div className="toggle-button-content">{content}</div>}
      {endIcon || null}
    </div>
  );
};

ToggleButton.propTypes = {
  value: PropTypes.bool.isRequired,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  content: PropTypes.string,
  onPressed: PropTypes.func,
  sx: PropTypes.oneOf(['large', 'medium', 'small', 'x-small']),
};

export default ToggleButton;
