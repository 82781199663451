import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import './tab.style.scss';

export default function Tab({ tabs, value, onSelected, sx }) {
  const tabClass = useMemo(() => {
    let className = 'tab-container';
    if (sx === 'medium') {
      className = `${className} medium-tab`;
    } else if (sx === 'small') {
      className = `${className} small-tab`;
    } else {
      className = `${className} large-tab`;
    }
    return className;
  }, [sx]);

  return (
    <div className={tabClass}>
      {tabs.map((tab, index) => {
        if (tab.hide) return null;

        return (
          <div
            className={`tab-item ${index === value ? 'tab-selected' : ''}`}
            key={`tab-${index}`}
            role="none"
            onClick={() => {
              onSelected(index);
            }}
          >
            {tab.text}
          </div>
        );
      })}
    </div>
  );
}

Tab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    }),
  ).isRequired,
  value: PropTypes.number.isRequired,
  onSelected: (props, propName, componentName) => {
    const fn = props[propName];

    if (typeof fn !== 'function' || fn.length !== 1) {
      return new Error(`${propName} must be a function with 1 number args`);
    }

    return null;
  },
  sx: PropTypes.oneOf(['large', 'medium', 'small']),
};
