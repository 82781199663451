import { useState, useEffect, useRef } from 'react';

import './progress-stepper.style.scss';
import { VectorIcon, ProgressCheck } from 'resources/icons';

export default function Steps({ steps }) {
  const ref = useRef();

  return (
    <div className="progress-stepper-container" ref={ref}>
      {steps.map((step, index) => (
        <>
          <div className={`item-${step.status}`}>
            {step.status === 'complete' ? <ProgressCheck /> : <div> {step.value} </div>}
          </div>
          {index < steps.length - 1 && <VectorIcon />}
        </>
      ))}
    </div>
  );
}
