import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from 'resources/icons';

import './back-button.style.scss';

export default function BackButton({ path, pageName, stateName = null, stateItem = null }) {
  const navigate = useNavigate();

  return (
    <div 
      className="back-button"
      role="none"
      onClick={() => {
        navigate(path,  {
          state: {
            [stateName] : stateItem
          }});
      }}
    >
      <div
        className="back-button-icon"
      >
        <ArrowLeftIcon />
      </div>
      {pageName}
    </div>
  );
}
