import { useEffect, useLayoutEffect, useRef } from 'react';
import { Drawer, MobileDialog } from 'components';
import { isMobileDevice } from 'utilities/validation';

import MenuListItem from './menulist-item';
import './menulist.style.scss';

export default function MenuList({ items, mobile, offset, onClose=null, padding }) {
  const ref = useRef();

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.tagName !== 'path'
      ) {
        onClose();
      }
    };
    document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, [ref]);

  useLayoutEffect(() => {
    if (offset && ref.current) {
      let newY = offset.y;

      const dropMenuWidth = 200;

      let newX = window.innerWidth - offset.x - dropMenuWidth * 2 - 50;
      const { right, height } = ref.current.getBoundingClientRect();
      if (
        offset.x + dropMenuWidth > window.innerWidth ||
        right + dropMenuWidth > window.innerWidth
      ) {
        newX += dropMenuWidth + 20;
      }

      // ref.current.style.setProperty('left', `${newX}px`);
      if (window.innerHeight - height <= newY) {
        newY = offset.y - height;
      }
      ref.current.style.setProperty('top', `${newY}px`);
    }

    if (padding && ref.current) {
      ref.current.style.setProperty('padding', `${padding}`);
    }
  }, []);

  if (!items) return null;

  if (isMobileDevice()) {
    return mobile === 'drawer' ? (
      <Drawer
        menu
        onClose={() => {
          onClose();
        }}
      >
        <div className="drawer-menu-container">
          {items.map((it, index) => (
            <MenuListItem
              key={`menuitem-${index}`}
              {...it}
              noPadding={padding}
              autoHeight
            />
          ))}
        </div>
      </Drawer>
    ) : (
      <MobileDialog
        onClose={() => {
          onClose();
        }}
      >
        {items.map((it, index) => (
          <MenuListItem
            key={`menuitem-${index}`}
            {...it}
            noPadding={padding}
            autoHeight
          />
        ))}
      </MobileDialog>
    );
  }

  return (
    <div className="menulist-container" ref={ref}>
      {items.map((it, index) => (
        <MenuListItem
          key={`menuitem-${index}`}
          {...it}
          func={() => {
            it.func();
            if (onClose) onClose();
          }}
          noPadding={padding}
          autoHeight
        />
      ))}
    </div>
  );
}
