import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // i18next-http-backend
  // loads translations from your server
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // lng: 'en', // if you're using a language detector, do not define the lng option
    // react: {
    //   useSuspense: false,
    // },
    fallbackLng: 'en',
    detection: {
      lookupLocalStorage: 'iws_lang',
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      requestOptions: {
          cache: 'no-store',
      },
    },
  });

export default i18n;
