import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SearchContext from './search-context';

export default function SearchProvider({ children }) {
  const {t} = useTranslation();
  const [filter, setFilter] = useState({id: 0, text: t('filter.all')});

  const value = useMemo(
    () => ({ filter, setFilter }),
    [filter],
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}
