import { useState, useEffect, useRef, useMemo, useId } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'utilities/regex';
import { HideIcon, EyeIcon, CreditCardFront, CreditCardBack } from 'resources/icons';
// import { ExclamationIcon } from 'resources/icons';
import { checkEmailValidation } from 'utilities/validation';

import './textfield.style.scss';

/**
 *
 * @param {string} fieldName: string
 */

export default function TextField({
  fieldName,
  required,
  type,
  placeholder,
  value,
  error,
  disabled,
  hideErrorMsg,
  onChange,
  readOnly,
  onKeyDown,
  onFocus,
  onAutofilled,
  onBlur,
  focused,
  changedValidation,
  borderRadius,
  gap,
  sx,
  helpText,
}) {
  const containerRef = useRef();
  const textfieldRef = useRef();
  const [invalid, setInvalid] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const textFieldId = useId();

  useEffect(() => {
    const input = document.getElementById('textfield');
    setTimeout(() => {
      if (input?.matches(':-internal-autofill-selected')) {
        if (textfieldRef.current) {
          textfieldRef.current.focus();
          if (onAutofilled) onAutofilled();
        }
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (focused && textfieldRef.current) {
      textfieldRef.current.focus();
    }
  }, [focused, textfieldRef]);

  useEffect(() => {
    if (borderRadius && textfieldRef.current) {
      textfieldRef.current.style.setProperty('border-radius', borderRadius);
    }

    if (borderRadius && containerRef.current) {
      containerRef.current.style.setProperty('gap', gap);
    }
  }, [borderRadius, gap, containerRef]);

  useEffect(() => {
    setInvalid(error);
  }, [error]);

  useEffect(() => {
    if (value) {
      if (type === 'email') {
        const validity = checkEmailValidation(value);
        setInvalid(validity.error);
        if (changedValidation) changedValidation(!!validity.valid);
      }
    }
  }, [value, type]);

  const inputType = useMemo(() => {
    if (type === 'password' || type === 'new-password') {
      if (showPassword) {
        return 'text';
      }
      return 'password';
    }
    if (type === 'number' || type === 'product-code') {
      return 'text';
    }

    return type;
  }, [type, showPassword]);

  return (
    <div ref={containerRef} className="textfield-container">
      {(fieldName || helpText) && (
        <div className="textfield-header">
          {fieldName && (
            <label
              className={`textfield-name ${required ? 'required-field' : ''}`}
              htmlFor={textFieldId}
            >
              {fieldName}
            </label>
          )}
          {helpText && (
            <label className={`textfield-help `} htmlFor={textFieldId}>
              {helpText}
            </label>
          )}
        </div>
      )}
      <div
        className={`textfield-input-wrapper ${
          invalid && !disabled ? 'invalid-input' : ''
        } ${disabled ? 'disabled' : ''} ${sx}`}
      >
        <input
          id={textFieldId}
          ref={textfieldRef}
          disabled={disabled}
          className={`textfield ${invalid && !disabled ? 'invalid-input' : ''} ${sx}`}
          required={required}
          placeholder={placeholder || ' '}
          type={inputType}
          autoComplete={type === 'new-password' ? 'new-password' : 'on'}
          readOnly={typeof readOnly === 'undefined' ? false : readOnly}
          value={value || ''}
          onKeyDown={(e) => {
            if (textfieldRef.current) {
              if (e.key === 'Escape') {
                textfieldRef.current.blur();
              }

              if (onKeyDown) onKeyDown(e);
            } else if (onKeyDown) onKeyDown(e);
          }}
          onFocus={() => {
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            if (onBlur) onBlur();
          }}
          onChange={(e) => {
            if (e.target) {
              let checkValid = true;
              const val = e.target.value;
              if (type === 'number') {
                checkValid = !val || isNumber(val);
              }

              if (checkValid) {
                onChange(val);
              }
            }
          }}
        />
        {(type === 'password' || type === 'new-password') &&
          !disabled &&
          (!showPassword ? (
            <div className="show-hide-icon">
              <HideIcon
                data-testid="hide-icon"
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              />
            </div>
          ) : (
            <div className="show-hide-icon">
              <EyeIcon
                data-testid="show-icon"
                width="20px"
                height="20px"
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              />
            </div>
          ))}
        {type === 'credit-card' && !disabled && (
          <div className="credit-card-icon">
            <CreditCardFront />
          </div>
        )}
        {type === 'credit-card-code' && !disabled && (
          <div className="credit-card-icon">
            <CreditCardBack />
          </div>
        )}
        {type && disabled && <div className="show-hide-icon" />}
      </div>
      {typeof error !== 'undefined' && !hideErrorMsg && (
        <div className="error-message">{invalid}</div>
      )}
    </div>
  );
}

TextField.propTypes = {
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'password',
    'tel',
    'new-password',
    'credit-card',
    'credit-card-code',
  ]).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  hideErrorMsg: PropTypes.bool, // remove the error message spot on the bottom of the textfield
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onAutofilled: PropTypes.bool,
  onBlur: PropTypes.func,
  focused: PropTypes.bool,
  changedValidation: PropTypes.func, // this will trigger the validation changes,
  borderRadius: PropTypes.string,
  gap: PropTypes.string,
  sx: PropTypes.oneOf(['small', 'medium', 'large']),
  helpText: PropTypes.string,
};
