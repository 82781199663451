import { forwardRef, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/loading/spinner';
import './button.style.scss';

const Button = forwardRef(
  (
    {
      startIcon,
      kind,
      sx,
      shape,
      loading,
      disabled,
      endIcon,
      content,
      width,
      noPadding,
      borderRadiusLeft,
      ...props
    },
    ref,
  ) => {
    const localRef = useRef();

    const buttonClass = useMemo(() => {
      let name = 'primary';

      // kind
      if (kind) {
        name = kind;
      }

      if (shape === 'outlined') {
        name = `${name} outlined`;
      }
      if (shape === 'text') {
        name = `${name} no-border-fill`;
      }

      // size
      if (sx === 'medium') {
        name = `${name} size-medium`;
      } else if (sx === 'small' && noPadding) {
        name = `${name} size-small-no-padding`;
      } else if (sx === 'small') {
        name = `${name} size-small`;
      } else if (sx === 'xsmall') {
        name = `${name} size-xsmall`;
      } else {
        name = `${name} size-large`;
      }

      return name;
    }, [kind, sx, shape, noPadding]);

    useEffect(() => {
      if (width && localRef.current) {
        localRef.current.style.setProperty('width', width);
      }
    }, [width]);

    return (
      <button
        className={buttonClass}
        type="button"
        {...props}
        disabled={disabled}
        ref={(node) => {
          localRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
        }}
      >
        {startIcon || null}
        {content && <div className="button-content">{content}</div>}
        {endIcon || null}
        {typeof loading !== 'undefined' &&
          (loading ? <Spinner shape={shape} sx={sx} /> : null)}
      </button>
    );
  },
);

Button.displayName = 'Button';

// these are customized props, and the rest of the props are the same as html (jsx) props, i.e) onClick, etc.
Button.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'negative']),
  sx: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  content: PropTypes.string,
  disabled: PropTypes.bool,
  shape: PropTypes.oneOf(['outlined', 'text']), // text will draw button without border
  width: PropTypes.string, //  manual button width
  noPadding: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
