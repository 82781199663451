import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';

import Dialog from './dialog';

import './confirm-dialog.style.scss';

const ConfirmDialog = ({
  title,
  description,
  type,
  buttonNames,
  onClick,
  open,
  showModal,
}) => (
  <Dialog open={open} showModal={showModal}>
    <div className="eb-dialog-body">
      <div className="dialog-title">{title}</div>
      <div className="dialog-description">{description}</div>
      <div className="dialog-buttons">
        <Button
          kind="secondary"
          shape="outlined"
          sx="large"
          content={buttonNames[0]}
          onClick={() => {
            onClick(0);
          }}
        />
        {buttonNames.length > 1 && (
          <Button
            kind={type === 'normal' ? 'primary' : 'negative'}
            sx="large"
            content={buttonNames[1]}
            onClick={() => {
              onClick(1);
            }}
          />
        )}
      </div>
    </div>
  </Dialog>
);
ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['normal', 'warn']), // second button color
  description: PropTypes.string,
  buttonNames: PropTypes.arrayOf(PropTypes.string), // upto 2 strings
  onClick: PropTypes.func.isRequired, // will receive the index of the button
  open: PropTypes.bool,
  showModal: PropTypes.bool,
};

export default ConfirmDialog;
