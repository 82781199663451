import { useEffect, useRef } from 'react';
import { MobileDialog } from 'components';
import { CheckMarkIcon } from 'resources/icons';
import { isMobileDevice } from 'utilities/validation';

import './palette.style.scss';

const colors = ['purple', 'blue', 'green', 'gold', 'orange', 'red'];
const levels = [400, 500, 600];
const extraColors = [
  'lime-500',
  'lime-600',
  'teal-500',
  'teal-600',
  'magenta-500',
  'magenta-600',
];

export default function Palette({ position, clsName, pick, onClose }) {
  const ref = useRef();

  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty('top', `${position.y}px`);
    }
  }, [position]);

  return isMobileDevice() ? (
    <MobileDialog
      onClose={() => {
        onClose();
      }}
    >
      <div className="mobile-palette-container">
        <div className="mobile-palette-classname">{clsName}</div>
        <div className="mobile-palette-wrapper">
          <ColorList pick={pick} onClose={onClose} />
        </div>
      </div>
    </MobileDialog>
  ) : (
    <div className="palette-container" ref={ref}>
      <ColorList pick={pick} onClose={onClose} />
    </div>
  );
}

const ColorList = ({ pick, onClose }) => (
  <>
    {levels.map((lv, index) => (
      <div className="color-pick-row" key={`${lv}-${index}`}>
        {colors.map((clr) => (
          <div
            className="color-pick"
            key={`${clr}-${lv}`}
            data-testid={`${clr}-${lv}`}
            style={{ backgroundColor: `var(--${clr}-${lv})` }}
            role="none"
            onClick={(e) => {
              e.stopPropagation();
              onClose(`${clr}-${lv}`);
            }}
          >
            {pick === `${clr}-${lv}` && <CheckMarkIcon className="palette-selected" />}
          </div>
        ))}
      </div>
    ))}
    <div className="color-pick-row" key="extra-color">
      {extraColors.map((clr) => (
        <div
          className="color-pick"
          key={clr}
          style={{ backgroundColor: `var(--${clr})` }}
          role="none"
          onClick={(e) => {
            e.stopPropagation();
            onClose(clr);
          }}
        >
          {pick === clr && <CheckMarkIcon className="palette-selected" />}
        </div>
      ))}
    </div>
  </>
);
