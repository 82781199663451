import React from 'react';

import './box.style.scss';

export default function Box({ width, height, color }) {
  const style = {
    backgroundColor: color,
  };
  if (width) {
    style.width = width;
  }
  if (height) style.height = height;

  return <div className="box-container" style={style} />;
}
