import { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ToastContext } from 'contexts';
import { CheckCircleIcon, AlertTriangleIcon, InfoIcon } from 'resources/icons';

import './toast.style.scss';

const Toast = ({ toast, type }) => {
  const { setOpen } = useContext(ToastContext);

  useEffect(() => {
    setTimeout(() => {
      if (setOpen) setOpen(false);
    }, 2200);
  }, []);

  const className = useMemo(() => {
    let name = 'toast-container';

    if (type === 'success') {
      name = `toast-container toast-success`;
    } else if (type === 'info') {
      name = `toast-container toast-info`;
    } else if (type === 'error') {
      name = `toast-container toast-error`;
    } else if (type === 'warn') {
      name = `toast-container toast-warn`;
    }

    return name;
  }, [type]);

  return (
    <div className={className}>
      {type === 'success' && <CheckCircleIcon />}
      {(type === 'error' || type === 'warn') && <AlertTriangleIcon />}
      {type === 'info' && <InfoIcon />}
      {toast}
    </div>
  );
};

Toast.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  toast: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'error', 'success', 'warn']),
};

export default Toast;
