import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components';

import './cookie.style.scss';

export default function CookieDialog({ onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="cookie-dialog-container">
      <p id="cookie-description">
        <Trans i18nKey="policy.cookies">
          ..
          <a
            href="https://www.anatomage.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            ..
          </a>
        </Trans>
      </p>
      <div className="cookie-btns">
        <Button
          width="fit-content"
          content="OK"
          kind="secondary"
          sx="small"
          shape="outlined"
          onClick={() => {
            // store the decision
            onClose();
          }}
        />
        <Button
          content="Privacy Policy"
          width="fit-content"
          kind="secondary"
          sx="small"
          shape="outlined"
          onClick={() => {
            window.open('https://www.anatomage.com/privacy-policy/', '_blank');
          }}
        />
      </div>
    </div>
  );
}
