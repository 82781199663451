import axios, { AxiosInstance } from 'axios';
import auth from 'configs/serverconfig';
import { APP_CONSTANTS } from 'common/strings';
import { v1 } from './apis';
import { accessToken, removeAccessToken, setAccessToken } from './storage';

let clientInstance;

const getClient = () => {
  if (!clientInstance) {
    clientInstance = axios.create({
      baseURL: auth.serverUrl, // process.env.REACT_APP_API_SERVER_URL,
      headers: { 'Content-Type': 'application/json' },
      // timeout: 10000,
    });
  }

  return clientInstance;
};

export const apiClient = getClient();

apiClient.interceptors.request.use(
  (config) => {
    const token = accessToken(); // Fetch the latest token from storage
    if (token) {
      return {
        ...config, // use existing config
        headers: {
          ...config.headers, // use existing headers
          Authorization: `Bearer ${token}`, // use new Authorization header
        },
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

let isTokenRefreshing = false;
const refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
  while (refreshSubscribers.length > 0) {
    const callback = refreshSubscribers.shift();
    if (callback) callback(accessToken);
  }
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

const postFailedRefreshToken = () => {
  removeAccessToken();
  try {
    apiClient.put(v1.usage.updateLastActivity, {});
  } catch (err) {
    console.error(err);
  }
  window.location.href = APP_CONSTANTS.ROUTES.SIGNIN;
};

export const setupClient = (token, AccountId) => {
  apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;

  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (originalRequest) {
        originalRequest.headers = { ...originalRequest.headers }; // picking up headers -> axios 1.1.3 issue
      }

      let autherizationFailed = false;
      let tokenRefreshed = false;
      if (error.response && error.response.status === 401) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          // removeAccessToken();
          try {
            const refreshRes = await apiClient.post(
              v1.account.refresh,
              { AccountId: parseInt(AccountId, 10) },
              { withCredentials: true },
            );
            if (refreshRes.data) {
              setAccessToken(refreshRes.data);

              // need to store new tokens here..
              apiClient.defaults.headers.common.Authorization = `Bearer ${refreshRes.data}`;

              tokenRefreshed = true;
              isTokenRefreshing = false;
            } else {
              autherizationFailed = true;
            }
          } catch (err) {
            autherizationFailed = true;
          }

          if (autherizationFailed) {
            postFailedRefreshToken();
          }
        }

        // during re-issuing token, request is stored in the refreshSubscribers
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;

            resolve(apiClient(originalRequest));
          });
        });

        if (tokenRefreshed) {
          // using refreshed token to process the request
          onTokenRefreshed(accessToken());
        }

        return retryOriginalRequest;
      }

      return Promise.reject(error);
    },
  );
};
