import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';

import './timeout.style.scss';

export default function TimeOutDialog({ onClose }) {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(120);
  const intervalRef = useRef();
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (timeLeft < 1 && intervalRef.current) {
      clearInterval(intervalRef.current);
      onClose(false);
    }
  }, [timeLeft]);

  return (
    <div className="timeout-dialog">
      <div className="timeout-body">
        <div className="timeout-title">
          {t('text.session-will-expire', { second: timeLeft })}
        </div>
        <div className="timeout-description">{t('text.select-continue-to-extend')}</div>
      </div>
      <Button
        kind="secondary"
        sx="small"
        width="fit-content"
        content={t('button.continue')}
        onClick={() => {
          onClose(true);
        }}
      />
    </div>
  );
}
