import { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { usePagination } from 'utilities/hooks';
import { apiClient } from 'common/api-client';
import { dateToISOString } from 'utilities/timestring';
import { v1 } from 'common/apis';
import ClassContext from './class-context';

export default function ClassProvider({ children }) {
  const [pastDue, setPastDue] = useState([]);
  const [deleted, setDeleted] = useState([]);

  const [sorted, setSorted] = useState();
  const [sortOption, setSortOption] = useState({ id: 0, text: '' });

  const [classes, setClasses] = useState([]);
  const [enoughCount, setEnoughCount] = useState(false); // this is for the time spent
  const [totalCurrent, setTotalCurrent] = useState(0);
  const [totalDeleted, setTotalDeleted] = useState(0);
  const [totalPast, setTotalPast] = useState(0);

  const SORT_OPTION = [
    {
      order_by: 'name',
      order: 'asc',
    },
    {
      order_by: 'name',
      order: 'desc',
    },
    {
      order_by: 'start_date',
      order: 'asc',
    },
    {
      order_by: 'start_date',
      order: 'desc',
    },
    {
      order_by: 'end_date',
      order: 'asc',
    },
    {
      order_by: 'end_date',
      order: 'desc',
    },
  ];

  const { loading, setLoading, paginationRef, setHasMore, page, setPage } =
    usePagination();
  const {
    loading: pastDueLoading,
    setLoading: setPastDueLoading,
    paginationRef: pastDuePaginationRef,
    setHasMore: setPastDueHasMore,
    setPage: setPastDuePage,
    page: pastDuePage,
  } = usePagination();

  const {
    loading: deletedLoading,
    setLoading: setDeletedLoading,
    paginationRef: deletedPaginationRef,
    setHasMore: setDeletedHasMore,
    setPage: setDeletedPage,
    page: deletedPage,
  } = usePagination();

  const refreshClasses = useCallback(
    (option, tab = 'current') => {
      if (tab === 'past') {
        if (pastDuePage === 1) {
          fetchClasses(option, tab);
        } else {
          setPastDuePage(1);
        }
      } else if (tab === 'deleted') {
        if (deletedPage === 1) {
          fetchClasses(option, tab);
        } else {
          setDeletedPage(1);
        }
      } else if (page === 1) {
        fetchClasses(option, tab);
      } else {
        setPage(1);
      }
    },
    [page, pastDuePage, deletedPage],
  );

  const refresh = useCallback(
    (props) => {
      refreshClasses(props);
      refreshClasses(props, 'past');
      refreshClasses(props, 'deleted');
    },
    [page, pastDuePage, deletedPage, sortOption],
  );

  useEffect(() => {
    fetchClasses(null, 'current');
  }, [page]);

  useEffect(() => {
    fetchClasses(null, 'past');
  }, [pastDuePage]);

  useEffect(() => {
    fetchClasses(null, 'deleted');
  }, [deletedPage]);

  const fetchClasses = useCallback(
    async (option, tab = 'current') => {
      try {
        let page_num = page;

        if (tab === 'past') {
          page_num = pastDuePage;
          setPastDueLoading(true);
        } else if (tab === 'deleted') {
          page_num = deletedPage;
          setDeletedLoading(true);
        } else {
          setLoading(true);
        }

        const sort = option?.sort?.id || sortOption.id;

        const params = {
          page_no: page_num,
          ...SORT_OPTION[sort],
        };

        if (tab === 'past') {
          params.end_date_to = dateToISOString(moment().subtract(1, 'day'));
        } else if (tab === 'current') {
          params.end_date_from = dateToISOString(moment());
        }
        if (tab === 'deleted') {
          params.deleted = true;
        }

        const response = await apiClient.get(v1.class.base, {
          params,
        });
        const { data, status } = response;
        const { pagination } = data;

        if (tab === 'past') {
          if (pastDuePage === 1) {
            setPastDue(data.data);
            setTotalPast(pagination?.total_records || 0);
          } else {
            setPastDue((prev) => [...prev, ...data.data]);
          }
          setPastDueHasMore(!!pagination?.next_page);
        } else if (tab === 'deleted') {
          if (deletedPage === 1) {
            setDeleted(data.data);
            setTotalDeleted(pagination?.total_records || 0);
          } else {
            setDeleted((prev) => [...prev, ...data.data]);
          }
          setDeletedHasMore(!!pagination?.next_page);
        } else {
          if (page === 1) {
            setClasses(data.data);
            setTotalCurrent(pagination?.total_records || 0);
          } else {
            setClasses((prev) => [...prev, ...data.data]);
          }
          setHasMore(!!pagination?.next_page);
        }
      } catch (err) {
        console.log(err);
      }

      if (tab === 'past') {
        setPastDueLoading(false);
      } else if (tab === 'deleted') {
        setDeletedLoading(false);
      } else {
        setLoading(false);
      }
    },
    [page, pastDuePage, deletedPage, sortOption],
  );

  const value = useMemo(
    () => ({
      sortOption,
      setSortOption,
      classes,
      setClasses,
      loading,
      deletedLoading,
      pastDueLoading,
      setLoading,
      pastDue,
      setPastDue,
      deleted,
      setDeleted,
      sorted,
      setSorted,
      fetchClasses,
      refreshClasses,
      paginationRef,
      pastDuePaginationRef,
      deletedPaginationRef,
      refresh,
      totalCurrent,
      totalDeleted,
      totalPast,
      enoughCount,
      setEnoughCount,
    }),
    [
      pastDue,
      deleted,
      sorted,
      sortOption,
      classes,
      loading,
      pastDueLoading,
      deletedLoading,
      paginationRef,
      pastDuePaginationRef,
      deletedPaginationRef,
      refresh,
      totalCurrent,
      totalDeleted,
      totalPast,
      enoughCount,
    ],
  );

  return <ClassContext.Provider value={value}>{children}</ClassContext.Provider>;
}
