import React from 'react';

import './terms-of-use.style.scss';

export default function TermsOfUse() {
  return (
    <div className="termsofuse-container">
      <div className="lastly-updated">
        <div className="header">Last Updated Date</div>
        <div className="date">8/30/2023</div>
      </div>
      <div className="termsofuse-body">
        <p>
          Welcome to Anatomage Lessons! Our website (the
          <b>
            <q>Platform</q>
          </b>
          ) is designed to provide educators and students (each, a
          <b>
            <q>User</q>
          </b>
          ) with educational materials created by Anatomage, Inc. The Platform is for
          EDUCATIONAL USE ONLY. This Terms of Use Agreement (<q>Terms of Use</q>) forms a
          binding agreement between you and Anatomage, Inc., and governs your use of the
          Platform as a User and the online services enabled via our Platform as well as
          the services we provide via the Platform (collectively, the
          <b>
            <q>Services</q>
          </b>
          ). Please read these Terms of Use carefully.
        </p>
        <p>
          THIS PLATFORM OF ANATOMAGE, INC. (
          <b>
            <q>ANATOMAGE</q>
          </b>
          ,
          <b>
            <q>WE</q>
          </b>
          OR
          <b>
            <q>US</q>
          </b>
          ) AND THE INFORMATION ON IT (OTHER THAN THE INFORMATION PROVIDED BY USERS) ARE
          CONTROLLED BY ANATOMAGE. THESE TERMS OF USE APPLY TO ALL INTERNET USERS VISITING
          THE PLATFORM BY ACCESS OR USING THE WEBSITE IN ANY WAY, INCLUDING USING THE
          SERVICES AND RESOURCES AVAILABLE OR ENABLED VIA THE PLATFORM. BY COMPLETING THE
          REGISTRATION PROCESS, ACCESSING, BROWSING, AND/OR USING THE WEBSITE, YOU
          REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS
          OF USE, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH ANATOMAGE, AND
          (3) YOU HAVE THE AUTHORITY TO ENTER INTO THE TERMS OF USE.
          <b>
            IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF USE, YOU MAY NOT ACCESS OR USE
            THIS PLATFORM OR THE SERVICES.
          </b>
        </p>
        <p>
          <b>
            PLEASE BE AWARE THAT SECTION 12 OF THESE TERMS, BELOW, CONTAINS PROVISIONS
            GOVERNING HOW CLAIMS THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED,
            INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED PRIOR TO
            THE EFFECTIVE DATE OF THIS AGREEMENT. IN PARTICULAR, IT CONTAINS AN
            ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES
            BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION. (1) YOU WILL ONLY
            BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL
            BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
            ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A
            COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS.
          </b>
        </p>
        <p>
          <b>
            ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO YOUR USE OF THE SITE WILL BE
            GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF CALIFORNIA,
            CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY
            PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANY OTHER
            JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL
            SALE OF GOODS IS EXPRESSLY EXCLUDED FROM THIS AGREEMENT.
          </b>
        </p>
        <p>
          Your use of, and participation in, certain Services may be subject to additional
          terms (
          <b>
            <q>Supplemental Terms</q>
          </b>
          ) and such Supplemental Terms will either be listed in the Terms of Use or will
          be presented to you for your acceptance when you sign up to use the supplemental
          Service. If the Terms of Use are inconsistent with the Supplemental Terms, the
          Supplemental Terms shall control with respect to such Service. The Terms of Use
          and any applicable Supplemental Terms are referred to herein as the
          <q>
            <b>Terms.</b>
          </q>
        </p>
        <p>
          PLEASE NOTE THAT THE TERMS ARE SUBJECT TO CHANGE BY ANATOMAGE IN ITS SOLE
          DISCRETION AT ANY TIME. When changes are made, Anatomage will make a new copy of
          the Terms of Use available on the Platform and any new Supplemental Terms will
          be made available from within, or through, the affected Service on the Platform.
          We will also update the <q>Last Updated</q> date at the top of the Terms of Use.
          Anatomage may require you to provide consent to the updated Terms in a specified
          manner before further use of the Platform, the Application and/or the Services
          is permitted. If you do not agree to any change(s) after receiving a notice of
          such change(s), you shall stop using the Platform and/or the Services.
          Otherwise, your continued use of the Platform and/or Services constitutes your
          acceptance of such change(s). PLEASE REGULARLY CHECK THE PLATFORM TO VIEW THE
          THEN-CURRENT TERMS.
        </p>

        <ol type="1">
          <li>
            <b>Services.</b>
            <ol type="1">
              <li>
                <b>
                  <u>Use of Services.</u>
                </b>
                Users can use the Platform to access educational materials that have been
                created and provided by Anatomage, Inc.
              </li>
            </ol>
          </li>

          <li>
            <b>Use of the Services and Anatomage Properties.</b> The Platform, the
            Services, and the information and content available on the Platform and the
            Services (collectively defined herein as the
            <b>
              <q>Anatomage Properties</q>
            </b>
            ) are protected by copyright laws throughout the world. Unless otherwise
            specified by Anatomage in a separate license, your right to use any and all
            Anatomage Properties is subject to the Agreement.
            <ol type="1">
              <li>
                <b>
                  <u>Updates.</u>
                </b>
                You understand that Anatomage Properties are evolving. As a result,
                Anatomage may require you to accept updates to Anatomage Properties. You
                acknowledge and agree that Anatomage may update Anatomage Properties with
                or without notifying you. You may need to update third-party software from
                time to time in order to use Anatomage Properties.
              </li>
              <li>
                <b>
                  <u>Certain Restrictions.</u>
                </b>
                The rights granted to you in the Terms are subject to the following
                restrictions: (a) you shall not frame or utilize framing techniques to
                enclose any trademark, logo, or other Anatomage Properties (including
                images, text, page layout or form) of Anatomage; (b) you shall not use any
                metatags or other <q>hidden text</q> using Anatomage&apos;s name or
                trademarks; (c) you shall not use any manual or automated software,
                devices or other processes (including but not limited to spiders, robots,
                scrapers, crawlers, avatars, data mining tools or the like) to{' '}
                <q>scrape</q> or download data from any web pages contained in the
                Platform (except that we grant the operators of public search engines
                revocable permission to use spiders to copy materials from the Platform
                for the sole purpose of and solely to the extent necessary for creating
                publicly available searchable indices of the materials, but not caches or
                archives of such materials); (d) you shall not access Anatomage Properties
                in order to build a similar or competitive website, application or
                service; and (e) except as expressly stated herein, no part of Anatomage
                Properties may be copied, reproduced, distributed, republished,
                downloaded, displayed, posted or transmitted in any form or by any means.
                Any future release, update or other addition to Anatomage Properties shall
                be subject to the Terms. Anatomage, its suppliers and service providers
                reserve all rights not granted in the Terms.
              </li>
            </ol>
          </li>
          <li>
            <b>Registration.</b>
            <ol type="1">
              <li>
                <b>
                  <u>Registering Your Account.</u>
                </b>
                In order to access certain features of Anatomage Properties you may be
                required to become a Registered User. For purposes of the Terms, a
                <b>
                  <q>Registered User</q>
                </b>
                is a User who has registered an account on the Platform (
                <b>
                  <q>Account</q>
                </b>
                ).
              </li>
              <li>
                <b>
                  <u>Account Eligibility.</u>
                </b>
                THE WEBSITE IS NOT AVAILABLE TO: (A) ANY USERS PREVIOUSLY SUSPENDED OR
                REMOVED FROM THE PLATFORM BY ANATOMAGE, OR (B) ANY PERSONS UNDER THE AGE
                OF 13. If you are under the age of 18, you represent that your parent or
                legal guardian has reviewed and agreed to the Terms on your behalf.
              </li>
              <li>
                <b>
                  <u>Registration Data.</u>
                </b>{' '}
                In registering an Account on the Platform, you agree to (1) provide true,
                accurate, current and complete information about yourself as prompted by
                the registration form (the
                <q>Registration Data</q>); and (2) maintain and promptly update the
                Registration Data to keep it true, accurate, current and complete. You
                represent that you are (1) of legal age to form a binding contract; and
                (2) not a person barred from using Anatomage Properties under the laws of
                the United States, your place of residence or any other applicable
                jurisdiction. You are responsible for all activities that occur under your
                Account. You may not share your Account or password with anyone, and you
                agree to notify Anatomage immediately of any unauthorized use of your
                password or any other breach of security. If you provide any information
                that is untrue, inaccurate, not current or incomplete, or Anatomage has
                reasonable grounds to suspect that such information is untrue, inaccurate,
                not current or incomplete, Anatomage has the right to suspend or terminate
                your Account and refuse any and all current or future use of Anatomage
                Properties (or any portion thereof). You agree not to create an Account or
                use Anatomage Properties if you have been previously removed by Anatomage,
                or if you have been previously banned from any of Anatomage Properties.
              </li>
            </ol>
          </li>
          <li>
            <b>Responsibility for Content.</b>
            <ol type="1">
              <li>
                <b>
                  <u>Types of Content.</u>
                </b>
                You acknowledge that all information, data, materials, photos, videos and
                other content (
                <b>
                  <q>Content</q>
                </b>
                ), and Anatomage Properties, is the sole responsibility of the party from
                whom such Content originated. This means that you, and not Anatomage, are
                entirely responsible for all Content that you upload, post, e-mail,
                transmit or otherwise make available (
                <b>
                  <q>Make Available</q>
                </b>
                ) through Anatomage Properties (
                <b>
                  <q>Your Content</q>
                </b>
                ).
              </li>
              <li>
                <b>
                  <u>No Obligation to Pre-Screen Content.</u>
                </b>
                You acknowledge that Anatomage has no obligation to pre-screen Content
                although Anatomage reserves the right in its sole discretion to
                pre-screen, refuse or remove any Content. By entering into the Terms, you
                hereby provide your irrevocable consent to such monitoring. In the event
                that Anatomage pre-screens, refuses or removes any Content, you
                acknowledge that Anatomage will do so for Anatomage&apos;s benefit, not
                yours. Without limiting the foregoing, Anatomage shall have the right to
                remove any Content that violates the Terms or is otherwise objectionable.
              </li>
              <li>
                <b>
                  <u>Storage.</u>
                </b>
                Unless expressly agreed to by Anatomage in writing elsewhere or as
                required by applicable law, Anatomage has no obligation to store any of
                Your Content and is not liable for loss of any of Your Content.
              </li>
            </ol>
          </li>
          <li>
            <b>Ownership.</b>
            <ol type="1">
              <li>
                <b>
                  <u>Anatomage Properties.</u>
                </b>
                Except with respect to Your Content, you agree that Anatomage and its
                suppliers own all rights, title and interest in Anatomage Properties.
              </li>
              <li>
                <b>
                  <u>License to Your Content.</u>
                </b>
                Subject to any applicable account settings that you select, you grant
                Anatomage a fully paid, royalty-free, worldwide, non-exclusive and
                sublicensable right and license to use, reproduce, publicly perform, and
                publicly display, Your Content (in whole or in part) for the purposes of
                operating and providing our Services to you. You warrant that the holder
                of any worldwide intellectual property right, in Your Content. You agree
                that you, not Anatomage, are responsible for all of Your Content that you
                Make Available on or in Anatomage Properties.
              </li>
              <li>
                <b>
                  <u>Feedback.</u>
                </b>
                You agree that submission of any ideas, suggestions, documents, and/or
                proposals to Anatomage through its suggestion, feedback, wiki, forum or
                similar pages (
                <b>
                  <q>Feedback</q>
                </b>
                ) is at your own risk and that Anatomage has no obligations (including
                without limitation obligations of confidentiality) with respect to such
                Feedback. You represent and warrant that you have all rights necessary to
                submit the Feedback. You hereby grant to Anatomage a fully paid,
                royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully
                sublicensable right and license to use, reproduce, perform, display,
                distribute, adapt, modify, re-format, create derivative works of, and
                otherwise commercially or non-commercially exploit in any manner, any and
                all Feedback, and to sublicense the foregoing rights, in connection with
                the operation and maintenance of Anatomage Properties.
              </li>
            </ol>
          </li>
          <li>
            <b>User Conduct.</b>
            <ol type="1">
              <li>
                You are responsible for your use of the Services, and for any use of the
                Services made using your Account. You hereby represent and warrant that
                Your Content does not violate our Acceptable Use Policy (defined in
                Section 6.2). Because you alone are responsible for Your Content, you may
                expose yourself to liability if, for example, Your Content violates the
                Acceptable Use Policy.
              </li>
              <li>
                <b>
                  <u>Acceptable Use Policy.</u>
                </b>
                This Section 6.2 constitutes our Acceptable Use Policy. You agree that you
                will only Make Available Contents that are professional and created and
                used in the ordinary course of education, and not for any other purpose.
                YOU AGREE THAT YOUR CONTENT THAT YOU MAKE AVAILABLE IS RELATED TO
                ANATOMAGE PRODUCTS AND SERVICES ONLY. You further agree that when you use
                the Services, you agree that you will NOT, under any circumstances:
                <ol type="a">
                  <li>
                    Make Available any Content that, in Anatomage&apos;s sole discretion,
                    (i) is unlawful, tortious, defamatory, vulgar, obscene, libelous, or
                    racially, ethnically or otherwise objectionable; (ii) violates, or
                    encourages any conduct that would violate, any applicable law or
                    regulation or would give rise to civil liability; (iii) promotes
                    discrimination, bigotry, racism, hatred, harassment or harm against
                    any individual or group; (iv) is violent or threatening, or promotes
                    violence or actions that are threatening to any other person; or (v)
                    promotes illegal or harmful activities; Harm minors in any way;
                  </li>
                  <li>
                    Impersonate any person or entity, including, but not limited to,
                    Anatomage personnel, or falsely state or otherwise misrepresent your
                    affiliation with a person or entity;
                  </li>
                  <li>
                    Make Available any Content that you do not have a right to Make
                    Available under any law or under contractual or fiduciary
                    relationships (such as inside information, proprietary and
                    confidential information learned or disclosed as part of employment
                    relationships or under non-disclosure agreements);
                  </li>
                  <li>
                    Make Available any Content that infringes the rights of any person or
                    entity, including without limitation, any patent, trademark, trade
                    secret, copyright, privacy, publicity or other proprietary or
                    contractual rights;
                  </li>
                  <li>
                    Use any Anatomage Content or the Platform for any commercial use or
                    purpose unless expressly permitted by Anatomage in writing;
                  </li>
                  <li>
                    Intentionally or unintentionally violate any applicable local, state,
                    national or international law or regulation, or any order of a court,
                    including, without limitation, laws governing intellectual property
                    and other proprietary rights, and data protection and privacy;
                  </li>
                  <li>
                    Register for more than one Account or register for an Account on
                    behalf of an individual other than yourself;
                  </li>
                  <li>
                    Stalk or otherwise harass any other user of the Anatomage Properties;
                    or
                  </li>
                  <li>
                    Advocate, encourage or assist any third party in doing any of the
                    foregoing activities in this section.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Interactions with Other Users.</b>
            <ol type="1">
              <li>
                <b>
                  <u>User Responsibility.</u>
                </b>
                You are solely responsible for your interactions with other Users;
                provided, however, that Anatomage reserves the right, but has no
                obligation, to intercede in such disputes. You agree that Anatomage will
                not be responsible for any liability incurred as the result of such
                interactions.
              </li>
              <li>
                <b>
                  <u>Release.</u>
                </b>
                You acknowledge that Anatomage merely provides a means for Users to share
                Contents with other Users, in order to collaborate related to those
                Contents. Anatomage does not have any control or authority over any Users,
                and is not responsible for any of their actions and inactions. You hereby
                release Anatomage and its successors from claims, demands, any and all
                losses, damages, rights, and actions of any kind, including personal
                injuries, death, and property damage, that is either directly or
                indirectly related to or arises from your use of Anatomage Properties. If
                you are a California resident, you hereby waive California Civil Code
                Section 1542, which states,
                <q>
                  A general release does not extend to claims which the creditor or
                  releasing party does not know or suspect to exist in his favor at the
                  time of executing the release, which, if known by him must have
                  materially affected his settlement with the debtor or the released
                  party.
                </q>
                The foregoing release does not apply to any claims, demands, or any
                losses, damages, rights and actions of any kind, including personal
                injuries, death or property damage for any unconscionable commercial
                practice by a Anatomage or for such party&apos;s fraud, deception, false,
                promise, misrepresentation or concealment, suppression or omission of any
                material fact in connection with the Platform or any Services provided
                hereunder.
              </li>
            </ol>
          </li>
          <li>
            <b>Indemnification.</b> You agree to indemnify and hold Anatomage and its
            respective parents, subsidiaries, affiliates, officers, employees, agents,
            partners and licensors (collectively, the “Anatomage Parties”) harmless from
            any losses, costs, liabilities and expenses (including reasonable
            attorneys&apos; fees) relating to or arising out of Your Contents, including
            your failure to obtain any necessary consents or rights in order to grant
            Anatomage the rights purportedly granted by you herein. Anatomage reserves the
            right, at its own cost, to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you, in which event you will
            fully cooperate with Anatomage in asserting any available defenses. This
            provision does not require you to indemnify any of the Anatomage Parties for
            such party&apos;s fraud, deception, false promise, misrepresentation or
            concealment, suppression or omission of any material fact in connection with
            the Platform or any Services provided hereunder. You agree that the provisions
            in this section will survive any termination of your Account, the Terms or
            your access to Anatomage Properties.
          </li>
          <li>
            <b>Disclaimer of Warranties and Conditions.</b>
            <ol type="1">
              <li>
                <b>
                  <u>As Is.</u>
                </b>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
                APPLICABLE LAW, YOUR USE OF ANATOMAGE PROPERTIES IS AT YOUR SOLE RISK, AND
                ANATOMAGE PROPERTIES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
                WITH ALL FAULTS. ANATOMAGE PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES,
                REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
                ARISING FROM USE OF THE PLATFORM.
                <ol type="a">
                  <li>
                    ANATOMAGE PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION THAT:
                    (1) ANATOMAGE PROPERTIES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE OF
                    ANATOMAGE PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                    ERROR-FREE; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF
                    COMPANY PROPERTIES WILL BE ACCURATE OR RELIABLE.
                  </li>
                  <li>
                    ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH ANATOMAGE
                    PROPERTIES IS ACCESSED AT YOUR OWN RISK, AND YOU SHALL BE SOLELY
                    RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT
                    LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS
                    ANATOMAGE PROPERTIES, OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING
                    SUCH CONTENT.
                  </li>
                  <li>
                    THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER
                    DISRUPTIONS. ANATOMAGE MAKES NO WARRANTY, REPRESENTATION OR CONDITION
                    WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO, THE QUALITY,
                    EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF SERVICES.
                  </li>
                  <li>
                    NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
                    COMPANY OR THROUGH ANATOMAGE PROPERTIES WILL CREATE ANY WARRANTY NOT
                    EXPRESSLY MADE HEREIN.
                  </li>
                  <li>
                    FROM TIME TO TIME, ANATOMAGE MAY OFFER NEW “BETA” FEATURES OR TOOLS
                    WITH WHICH ITS USERS MAY EXPERIMENT. SUCH FEATURES OR TOOLS ARE
                    OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND WITHOUT ANY WARRANTY OF
                    ANY KIND, AND MAY BE MODIFIED OR DISCONTINUED AT COMPANY&apos;S SOLE
                    DISCRETION. THE PROVISIONS OF THIS SECTION APPLY WITH FULL FORCE TO
                    SUCH FEATURES OR TOOLS.
                  </li>
                </ol>
              </li>

              <li>
                <b>
                  <u>No Liability for Conduct of Users.</u>
                </b>
                YOU ACKNOWLEDGE AND AGREE THAT ANATOMAGE PARTIES ARE NOT LIABLE, AND YOU
                AGREE NOT TO SEEK TO HOLD ANATOMAGE PARTIES LIABLE, FOR THE CONDUCT OF
                USERS, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY
                WITH YOU.
              </li>
            </ol>
          </li>
          <li>
            <b>Limitation of Liability.</b>
            <ol type="1">
              <li>
                <b>
                  <u>Disclaimer of Certain Damages.</u>
                </b>
                YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL ANATOMAGE PARTIES BE
                LIABLE FOR ANY LOSS OF PROFITS, REVENUE OR DATA, INDIRECT, INCIDENTAL,
                SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                ANATOMAGE PROPERTIES, OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR
                USE, BUSINESS INTERRUPTION, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
                WHETHER OR NOT ANATOMAGE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE TERMS, OR FROM ANY
                COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF ANATOMAGE
                PROPERTIES, ON ANY THEORY OF LIABILITY, RESULTING FROM: (1) THE USE OR
                INABILITY TO USE ANATOMAGE PROPERTIES; (2) THE COST OF PROCUREMENT OF
                SUBSTITUTE GOODS OR SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION
                OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED FOR TRANSACTIONS
                ENTERED INTO THROUGH ANATOMAGE PROPERTIES; (3) UNAUTHORIZED ACCESS TO OR
                ALTERATION OF YOUR TRANSMISSIONS OR DATA; (4) STATEMENTS OR CONDUCT OF ANY
                THIRD PARTY ON ANATOMAGE PROPERTIES; OR (5) ANY OTHER MATTER RELATED TO
                ANATOMAGE PROPERTIES, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT
                (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY.
              </li>
              <li>
                <b>
                  <u>Cap on Liability.</u>
                </b>
                Anatomage is not liable for any incidental, indirect, special, or
                consequential damages arising out of or in connection with the Services
                provided by Anatomage, including without limitation loss of use of the any
                products, including inability to achieve a particular result.
                Anatomage&apos;s total liability arising out of or in connection with any
                event or series of connected events occurring in connection with the
                Services shall not exceed the amount of fees paid for use of the Services.
                These provisions allocate the risks under use of the Services.
                Anatomage&apos;s pricing reflects this allocation of risk and the
                limitation of liability specified herein.
              </li>
              <li>
                <b>
                  <u>Basis of the Bargain.</u>
                </b>
                THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE
                BASIS OF THE BARGAIN BETWEEN ANATOMAGE AND YOU.
              </li>
            </ol>
          </li>
          <li>
            <b>Term and Termination.</b>
            <ol type="1">
              <li>
                <b>
                  <u>Term.</u>
                </b>
                The Terms commence on the date when you accept them (as described in the
                preamble above) and remain in full force and effect while you use
                Anatomage Properties, unless terminated earlier in accordance with the
                Terms.
              </li>
              <li>
                <b>
                  <u>Termination of Services by Us.</u>
                </b>
                We may terminate and/or suspend the Services, your Account and/or these
                Terms in the event you breach any terms herein, or if required to do so by
                applicable law. Any suspected fraudulent, abusive or illegal activity may
                be referred to appropriate law enforcement authorities.
              </li>
              <li>
                <b>
                  <u>Termination of Services by You.</u>
                </b>
                If you want to terminate the Services provided by Anatomage, you may do so
                by (a) notifying Anatomage at any time and (b) closing your Account for
                all of the Services that you use. Your notice should be sent, in writing,
                to Anatomage&apos;s address set forth below in 13.7.
              </li>
              <li>
                <b>
                  <u>Effect of Termination.</u>
                </b>
                Termination of any Service includes removal of access to such Service and
                barring of further use of the Service. Termination of all Services also
                includes deletion of your password and all related information, files and
                Content associated with or inside your Account (or any part thereof),
                including Your Content, unless we are required to retain a copy of such
                Content in accordance with applicable law. Upon termination of any
                Service, your right to use such Service will automatically terminate
                immediately. You understand that any termination of Services may involve
                deletion of Your Content associated therewith from our live databases.
                Anatomage will not have any liability whatsoever to you for any suspension
                or termination, including for deletion of Your Content. All provisions of
                the Terms which by their nature should survive, shall survive termination
                of Services, including without limitation, ownership provisions, warranty
                disclaimers, and limitation of liability.
              </li>
            </ol>
          </li>
          <li>
            <b>
              Dispute Resolution. Please read the following arbitration agreement in this
              Section (“Arbitration Agreement”) carefully. It requires you to arbitrate
              disputes with Anatomage and limits the manner in which you can seek relief
              from us.
            </b>
            <ol type="1">
              <li>
                <b>
                  <u>Applicability of Arbitration Agreement.</u>
                </b>
                You agree that any dispute or claim relating in any way to your access or
                use of the Platform, to any products sold or distributed through the
                Platform, or to any aspect of your relationship with Anatomage, will be
                resolved by binding arbitration, rather than in court, except that (1) you
                may assert claims in small claims court if your claims qualify; and (2)
                you or Anatomage may seek equitable relief in court for infringement or
                other misuse of intellectual property rights (such as trademarks, trade
                dress, domain names, trade secrets, copyrights, and patents).
                <b>
                  This Arbitration Agreement shall apply, without limitation, to all
                  claims that arose or were asserted before the Effective Date of this
                  Agreement or any prior version of this Agreement.
                </b>
              </li>
              <li>
                <b>
                  <u>Arbitration Rules and Forum.</u>
                </b>
                The Federal Arbitration Act governs the interpretation and enforcement of
                this Arbitration Agreement. To begin an arbitration proceeding, you must
                send a letter requesting arbitration and describing your claim to
                Anatomage, 3350 Thomas Rd, Suite 150, Santa Clara, California. The
                arbitration will be conducted by JAMS, an established alternative dispute
                resolution provider. Disputes involving claims and counterclaims under
                $250,000, not inclusive of attorneys&apos; fees and interest, shall be
                subject to JAMS&apos;s most current version of the Streamlined Arbitration
                Rules and procedures available at
                <a href="http://www.jamsadr.com/rules-streamlined-arbitration/">
                  http://www.jamsadr.com/rules-streamlined-arbitration/
                </a>
                ; all other claims shall be subject to JAMS&apos;s most current version of
                the Comprehensive Arbitration Rules and Procedures, available at
                <a href="http://www.jamsadr.com/rules-comprehensive-arbitration/">
                  http://www.jamsadr.com/rules-comprehensive-arbitration/
                </a>
                . JAMS&apos;s rules are also available at www.jamsadr.com or by calling
                JAMS at <a href="tel:800-352-5267">800-352-5267</a>. If JAMS is not
                available to arbitrate, the parties will select an alternative arbitral
                forum.
              </li>
              <li>
                <b>
                  <u>Authority of Arbitrator.</u>
                </b>
                The arbitrator shall have exclusive authority to (a) determine the scope
                and enforceability of this Arbitration Agreement and (b) resolve any
                dispute related to the interpretation, applicability, enforceability or
                formation of this Arbitration Agreement including, but not limited to any
                claim that all or any part of this Arbitration Agreement is void or
                voidable. The arbitration will decide the rights and liabilities, if any,
                of you and Anatomage. The arbitration proceeding will not be consolidated
                with any other matters or joined with any other cases or parties. The
                arbitrator shall have the authority to grant motions dispositive of all or
                part of any claim. The arbitrator shall have the authority to award
                monetary damages and to grant any non-monetary remedy or relief available
                to an individual under applicable law, the arbitral forum&apos;s rules,
                and the Agreement (including the Arbitration Agreement). The arbitrator
                shall issue a written award and statement of decision describing the
                essential findings and conclusions on which the award is based, including
                the calculation of any damages awarded. The arbitrator has the same
                authority to award relief on an individual basis that a judge in a court
                of law would have. The award of the arbitrator is final and binding upon
                you and us.
              </li>
              <li>
                <b>
                  <u>Waiver of Jury Trial.</u>
                </b>
                YOU AND ANATOMAGE HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO
                SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and
                Anatomage are instead electing that all claims and disputes shall be
                resolved by arbitration under this Arbitration Agreement, except as
                specified in Section 12.1 above. An arbitrator can award on an individual
                basis the same damages and relief as a court and must follow this
                Agreement as a court would. However, there is no judge or jury in
                arbitration, and court review of an arbitration award is subject to very
                limited review.
              </li>
              <li>
                <b>
                  <u>Waiver of Class or Other Non-Individualized Relief.</u>
                </b>
                ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT
                MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE
                BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE
                CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY
                OTHER CUSTOMER OR USER. If a decision is issued stating that applicable
                law precludes enforcement of any of this subsection&apos;s limitations as
                to a given claim for relief, than then claim must be severed from the
                arbitration and brought into the State or Federal Courts located in Santa
                Clara County, California. All other claims shall be arbitrated.
              </li>
              <li>
                <b>
                  <u>Severability.</u>
                </b>
                Except as provided in subsection 12.5, if any part or parts of this
                Arbitration Agreement are found under the law to be invalid or
                unenforceable, then such specific part or parts shall be of no force and
                effect and shall be severed and the remainder of the Arbitration Agreement
                shall continue in full force and effect.
              </li>
              <li>
                <b>
                  <u>Survival of Agreement.</u>
                </b>
                This Arbitration Agreement will survive the termination of your
                relationship with Anatomage.
              </li>
            </ol>
          </li>
          <li>
            <b>General Provisions.</b>
            <ol type="1">
              <li>
                <b>
                  <u>Electronic Communications.</u>
                </b>
                The communications between you and Anatomage use electronic means, whether
                you visit Anatomage Properties or send Anatomage e-mails, or whether
                Anatomage posts notices on Anatomage Properties or communicates with you
                via e-mail. For contractual purposes, you (1) consent to receive
                communications from Anatomage in an electronic form; and (2) agree that
                all terms and conditions, agreements, notices, disclosures, and other
                communications that Anatomage provides to you electronically satisfy any
                legal requirement that such communications would satisfy if it were to be
                in writing. The foregoing does not affect your statutory rights.
              </li>
              <li>
                <b>
                  <u>Assignment.</u>
                </b>
                The Terms, and your rights and obligations hereunder, may not be assigned,
                subcontracted, delegated or otherwise transferred by you without
                Anatomage&apos;s prior written consent, and any attempted assignment,
                subcontract, delegation, or transfer in violation of the foregoing will be
                null and void. Anatomage may freely assign these Terms including its
                rights herein, in whole or in part, without your prior consent.
              </li>
              <li>
                <b>
                  <u>Force Majeure.</u>
                </b>
                Anatomage shall not be liable for any delay or failure to perform
                resulting from causes outside its reasonable control, including, but not
                limited to, acts of God, war, terrorism, riots, embargos, acts of civil or
                military authorities, fire, floods, accidents, strikes or shortages of
                transportation facilities, fuel, energy, labor or materials.
              </li>
              <li>
                <b>
                  <u>Questions, Complaints, Claims.</u>
                </b>
                If you have any questions, complaints or claims with respect to Anatomage
                Properties, please contact us at: Anatomage, 3350 Thomas Rd, Suite 150,
                Santa Clara, California. We will do our best to address your concerns. If
                you feel that your concerns have been addressed incompletely, we invite
                you to let us know for further investigation.
              </li>
              <li>
                <b>
                  <u>Exclusive Venue.</u>
                </b>
                To the extent the parties are permitted under this Agreement to initiate
                litigation in a court, both you and Anatomage agree that all claims and
                disputes arising out of or relating to the Agreement will be litigated
                exclusively in the state or federal courts located in Santa Clara County,
                California.
              </li>
              <li>
                <b>
                  <u>Governing Law.</u>
                </b>
                THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND INTERPRETED
                BY AND UNDER THE LAWS OF THE STATE OF CALIFORNIA, CONSISTENT WITH THE
                FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT
                PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION. THE UNITED
                NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS DOES
                NOT APPLY TO THESE TERMS.
              </li>
              <li>
                <b>
                  <u>Notice.</u>
                </b>
                Where Anatomage requires that you provide an e-mail address, you are
                responsible for providing Anatomage with your most current e-mail address.
                In the event that the last e-mail address you provided to Anatomage is not
                valid, or for any reason is not capable of delivering to you any notices
                required/ permitted by the Terms, Anatomage&apos;s dispatch of the e-mail
                containing such notice will nonetheless constitute effective notice. You
                may give notice to Anatomage at the following address: Anatomage, 3350
                Thomas Rd, Suite 150, Santa Clara, California. Such notice shall be deemed
                given when received by Anatomage by letter delivered by nationally
                recognized overnight delivery service or first class postage prepaid mail
                at the above address.
              </li>
              <li>
                <b>
                  <u>Waiver.</u>
                </b>
                Any waiver or failure to enforce any provision of the Terms on one
                occasion will not be deemed a waiver of any other provision or of such
                provision on any other occasion.
              </li>
              <li>
                <b>
                  <u>Severability.</u>
                </b>
                If any portion of this Agreement is held invalid or unenforceable, that
                portion shall be construed in a manner to reflect, as nearly as possible,
                the original intention of the parties, and the remaining portions shall
                remain in full force and effect.
              </li>
              <li>
                <b>
                  <u>Consumer Complaints.</u>
                </b>
                In accordance with California Civil Code §1789.3, you may report
                complaints to the Complaint Assistance Unit of the Division of Consumer
                Services of the California Department of Consumer Affairs by contacting
                them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at
                <a href="tel:(800) 952-5210">(800) 952-5210</a>.
              </li>
              <li>
                <b>
                  <u>Entire Agreement.</u>
                </b>
                The Terms are the final, complete and exclusive agreement of the parties
                with respect to the subject matter hereof and supersedes and merges all
                prior discussions between the parties with respect to such subject matter.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}
