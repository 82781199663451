import { useEffect, useRef } from 'react';
import { XIcon } from 'resources/icons';
import { createPortal } from 'react-dom';
import { IconButton } from 'components';

import './drawer.style.scss';

export default function Drawer({
  children,
  short,
  menu,
  title,
  subtitle,
  footer,
  showCloseButton,
  onClose,
}) {
  const ref = useRef();
  useEffect(() => {
    const checkOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (onClose) onClose(false);
      }
    };
    if (onClose) document.addEventListener('mousedown', checkOutsideClick);
    return () => {
      if (onClose) document.removeEventListener('mousedown', checkOutsideClick);
    };
  }, [ref]);

  return createPortal(
    <div className="drawer-container">
      <div
        className={`drawer-dialog ${short ? 'short-ver' : ''} ${menu ? 'menu-ver' : ''}`}
        ref={ref}
      >
        {title && (
          <div className="drawer-bar">
            <div className="drawer-title">
              {title}
              <div className="drawer-subtitle">{subtitle}</div>
            </div>
            {showCloseButton && (
              <IconButton
                icon={<XIcon />}
                noPadding
                sx="xsmall"
                kind="icon-only"
                onClick={() => {
                  if (onClose) onClose(true);
                }}
              />
            )}
          </div>
        )}
        <div className={`drawer-body ${menu ? 'menu-ver-body' : ''}`}>{children}</div>
        {footer}
      </div>
    </div>,
    document.querySelector('#root'),
  );
}
