/* eslint-disable global-require */
import { useEffect, useMemo } from 'react';
import lottie from 'lottie-web';

export default function PageLoading({ shape, sx }) {
  useEffect(() => {
    lottie.destroy();
    lottie.loadAnimation({
      container: document.getElementById('spinner'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('resources/animation/loading-dots.json'),
    });
  }, [shape]);

  const styles = useMemo(() => {
    const sty = { width: '173px', height: '40px', alignSelf: 'center' };
    if (sx === 'small') {
      sty.width = '103px';
      sty.height = '24px';
    }

    return sty;
  }, [sx]);

  return <div style={styles} id="spinner" />;
}
