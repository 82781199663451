import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useContext,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePickerContext } from 'contexts';
import { CalendarIcon } from 'resources/icons';
import { dateToISOString, isBefore } from 'utilities/timestring';
import { isMobileDevice } from 'utilities/validation';
import { MobileDialog } from 'components';

import DatePickerCalendar from './datepicker-calendar';
import './datepicker.style.scss';

const DatePicker = forwardRef(
  (
    {
      date,
      isStart,
      onOpen,
      textfield,
      placeholdertext,
      error,
      maxWidth = false,
      disablePast = false,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const { dates, setDates } = useContext(DatePickerContext);

    const refer = useRef();

    useEffect(() => {
      if (maxWidth && refer.current) {
        refer.current.style.setProperty('width', '100%');
      }
    }, []);

    useImperativeHandle(ref, () => ({
      close() {
        setOpen(false);
      },
    }));

    if (dates.length === 0) return null;

    return (
      <div
        ref={refer}
        className={`date-picker-container${textfield ? '-textfield' : ''} ${
          error ? 'invalid' : ''
        }`}
        role="none"
        onClick={() => {
          if (!open && onOpen) {
            onOpen();
          }

          if (!(open && isMobileDevice())) {
            setOpen((prev) => !prev);
          }
        }}
      >
        {!textfield && (
          <div className="date-picker-box">
            <CalendarIcon />
            {moment.utc(date).format('MMM DD, YYYY')}
          </div>
        )}
        {textfield && (
          <div className="date-picker-box">
            <div className={date ? 'date' : 'placeholder'}>
              {date ? moment.utc(date).format('MMM DD, YYYY') : placeholdertext}
            </div>
            <div className="icon">
              <CalendarIcon />
            </div>
          </div>
        )}
        {open &&
          (isMobileDevice() ? (
            <MobileDialog
              onClose={() => {
                setOpen(false);
              }}
            >
              <DatePickerCalendar
                value={date || dateToISOString(moment())}
                isStart={isStart}
                disablePast={disablePast}
                onPicked={(res) => {
                  if (isStart) {
                    if (isBefore(moment.utc(dates[1]), moment.utc(res))) {
                      setDates([res, res]);
                    } else {
                      setDates([res, dates[1]]);
                    }
                  } else if (isBefore(moment.utc(res), moment.utc(dates[0]))) {
                    setDates([res, res]);
                  } else {
                    setDates([dates[0], res]);
                  }
                  setOpen(false);
                }}
              />
            </MobileDialog>
          ) : (
            <div className="dp-calendar-wrapper">
              <DatePickerCalendar
                value={date || dateToISOString(moment.utc())}
                isStart={isStart}
                disablePast={disablePast}
                onPicked={(res) => {
                  if (isStart) {
                    if (isBefore(moment.utc(dates[1]), moment.utc(res))) {
                      setDates([res, res]);
                    } else {
                      setDates([res, dates[1]]);
                    }
                  } else if (isBefore(moment.utc(res), moment.utc(dates[0]))) {
                    setDates([res, res]);
                  } else {
                    setDates([dates[0], res]);
                  }
                  setOpen(false);
                }}
              />
            </div>
          ))}
      </div>
    );
  },
);

DatePicker.propTypes = {
  date: PropTypes.string, // utc ISO string format
  onOpen: PropTypes.func, // this can be used when we use multiple date picker
  textfield: PropTypes.bool,
  placeholdertext: PropTypes.string,
  error: PropTypes.bool,
  maxWidth: PropTypes.bool,
  disablePast: PropTypes.bool,
};

export default DatePicker;
