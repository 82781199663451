export const subjectImageUrl = (subjectId) => {
  let url = '/static/biology.png';
  // 1: anatomy & physiology 2: chemistry, 3: biology 4. physics
  if (subjectId === 1) {
    url = '/static/anatomy-physiology.png';
  } else if (subjectId === 2) {
    url = '/static/chemistry.png';
  } else if (subjectId === 4) {
    url = '/static/physics.png';
  }

  return url;
};
