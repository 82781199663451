/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './radio-button.style.scss';

const RadioButton = ({ label, checked, onChanged, autoWidth = false }) => {
  const [on, setOn] = useState(checked);
  const ref = useRef();

  useEffect(() => {
    if (autoWidth) ref.current.style.setProperty('width', 'auto');
  });

  useEffect(() => {
    setOn(checked);
  }, [checked]);

  return (
    <div className="radio-wrapper" ref={ref}>
      <input
        type="radio"
        id={label}
        className="radio-button"
        name="radio-group"
        checked={on}
        onChange={(e) => {
          e.stopPropagation();
          if (onChanged) {
            // setIsChecked(true);
            onChanged(e.currentTarget.id.toLowerCase());
          }
        }}
      />
      {label && (
        <label className={`radio-label ${on ? 'checked-label' : ''}`} htmlFor={label}>
          {label}
        </label>
      )}
    </div>
  );
};

RadioButton.propType = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChanged: PropTypes.func,
};

export default RadioButton;
